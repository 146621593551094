import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import login01 from "../../assets/login01.png";
import login02 from "../../assets/login02.png";
import login03 from "../../assets/login03.png";
import login04 from "../../assets/login04.png";
import header_logo from "../../assets/header_logo.png";
import energyLogoImg from "../../assets/logo_gj.png";
import backImg from "../../assets/ic_back.png";
import { useDialog } from "../../dialog/DialogProvider";
import LoginDialog from "../../dialog/Login/LoginDialog";
import JoinAgreeDialog from "../../dialog/Join/JoinAgreeDialog";
import useMember from "../../hooks/useMember";
import MemberAPI from "../../apis/MemberAPI";
import MemberInfo from "../../models/MemberInfo";
import OneButtonAlertDialog from "../../dialog/OneButtonAlertDialog";
import CommSelect from "./CommSelect";
import { content } from "../../models/TownList";
import TownAPI from "../../apis/TownAPI";
interface TopNavBarProp {}
const TopNavBar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  // const { member, getMemInfo } = useMember();

  let headerTitle = "";
  let option = "";
  let option2 = "";
  if (location.pathname.includes("join_04")) {
    headerTitle = "회원가입";
  }
  if (location.pathname.includes("reward_02/challenge")) {
    headerTitle = "에너지전환 챌린지";
  }
  if (location.pathname.includes("reward_02/challenge_04-write")) {
    headerTitle = "챌린지 만들기";
  }
  if (location.pathname.includes("reward_02/challenge_04-modify")) {
    headerTitle = "챌린지 수정하기";
  }
  if (location.pathname.includes("reward_02/myEPoint")) {
    headerTitle = "내 E 포인트";
  }
  if (location.pathname.includes("reward_02/mapMission_04-write")) {
    headerTitle = "지도미션 만들기";
  }
  if (location.pathname.includes("reward_02/mapMission_04-modify")) {
    headerTitle = "지도미션 수정하기";
  }
  if (location.pathname.includes("reward_02/mapMission_05-detail")) {
    headerTitle = "지도미션";
  }
  if (location.pathname.includes("reward_02/mission_05-detail")) {
    headerTitle = "기본미션";
  }
  if (location.pathname.includes("reward_02/villageMission_05-detail")) {
    headerTitle = "마을미션";
  }
  if (location.pathname.includes("reward_02/mission_03-list")) {
    option = "기본미션";
  }
  if (location.pathname.includes("reward_02/villageMission_03-list")) {
    option2 = "마을미션";
  }
  if (location.pathname.includes("reward_02/mission_04-write")) {
    headerTitle = "기본미션 작성하기";
  }
  if (location.pathname.includes("reward_02/villageMission_04-write")) {
    headerTitle = "마을미션 작성하기";
  }
  if (location.pathname.includes("notice_02")) {
    headerTitle = "공지사항";
  }
  if (location.pathname.includes("popup_02")) {
    headerTitle = "팝업 관리";
  }
  // else if (location.pathname.includes('login_04')) {
  // 	headerTitle = '로그인';
  // }
  // else if (location.pathname.includes('commu_02')) {
  // 	headerTitle = '소통마당';
  // }

  const handleBack = () => {
    // if (location.pathname.includes('commu_02')) {
    // 	navigate('/');
    // } else {
    navigate(-1);
    // }

    // window.location.reload();
  };
  const townId = sessionStorage.getItem("town");
  const [townList, setTownList] = useState([] as content[]);
  const [townInfo, setTownInfo] = useState([] as content[]);
  // const [selectedTown, setSelectedTown] = useState<number | undefined>();
  // 선택한 마을 townId
  // const changeTownList = (townId: number) => {
  //     setSelectedTown(townId);
  // };

  useEffect(() => {
    TownAPI.getTownList().then((res) => {
      if (res) {
        setTownList(res.content);
        setTownInfo(
          res &&
            res.content &&
            res.content.filter((list) => list.townId === Number(townId))
        );
        res &&
          res.content &&
          res.content.map((list, index) => {
            list.townTitle = list.districtDTOList
              .map((list2, index2) => list2.districtName)
              .join(", ");
          });
      }
    });
  }, [townId]);
  // select 페이지 이동

  const [selectedValue, setSelectedValue] = useState("");

  useEffect(() => {
    if (window.document.location.href.includes("villageMission_03-list")) {
      setSelectedValue("villageMission");
    }
    if (window.document.location.href.includes("mission_03-list")) {
      setSelectedValue("basicMission");
    }
  }, [window.document.location.href]);
  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = e.target.value;
    // console.log('selectedValue', selectedValue);

    // 선택된 옵션에 따라 페이지 이동을 처리
    if (selectedValue === "villageMission") {
      setSelectedValue(e.target.value);
      navigate("/reward_02/villageMission_03-list");
    } else if (selectedValue === "basicMission") {
      setSelectedValue(e.target.value);
      navigate("/reward_02/mission_03-list");
    }
  };
  // useEffect(() => {
  // 	// 페이지 로드 후에 초기 선택을 "기본 미션"으로 설정
  // 	setSelectedValue('basicMission');
  // }, []);
  const [reward, setReward] = useState<boolean>(false);
  const [notice, setNotice] = useState<boolean>(false);
  const [popup, setPopup] = useState<boolean>(false);
  // Url 가져오기
  const url = window.location.href;
  useEffect(() => {
    // console.log('url:', url);
    if (url.includes("/reward_02")) {
      setReward(true);
    } else {
      setReward(false);
    }
  }, [url]);
  useEffect(() => {
    if (window.innerWidth < 1024) {
      if (url.includes("/notice_02/notice_03-detail")) {
        setNotice(true);
      } else {
        setNotice(false);
      }
    }
  }, [url]);
  // useEffect(() => {
  // 	// console.log('url:', url);
  // 	if (url.includes('/popup_02/list')) {
  // 		setPopup(false);
  // 	}
  // 	else if (url.includes('/popup_02')) {
  // 		setPopup(true);
  // 	}
  // }, [url]);

  return (
    <header>
      <h1 className="notext">헤더 영역</h1>
      <div id="header">
        {/* 상단 로그인 메뉴 */}
        <HeaderLogin />
        <div className="header_menu">
          {/* 네비게이션 바 */}
          <NavBar />

          {/* sub_village_wrapper , write_wrapper */}
          <a className="header_back">
            <img src={backImg} onClick={handleBack} alt="마이페이지 메인으로" />
          </a>

          {/* write_wrapper */}
          <p className="header_title">{headerTitle}</p>

          {/* sub_village_wrapper */}

          {/* 참여마당 select */}
          {
            // notice ?
            // 	<p style={{ fontSize: '20px', fontWeight: '600' }}>{`${popup ? '팝업 관리' : '공지사항'}`}</p>
            // 	:
            reward ? (
              <>
                {" "}
                <select onChange={handleSelectChange} value={selectedValue}>
                  <option value="basicMission">기본미션</option>
                  <option value="villageMission">마을미션</option>
                </select>
              </>
            ) : (
              <>
                {/* 소통마당 select */}
                {!notice ? (
                  <CommSelect townList={townList} />
                ) : (
                  <p
                    style={{
                      fontSize: "1.6rem",
                      fontWeight: "600",
                      marginLeft: "auto",
                    }}
                  >
                    공지사항
                  </p>
                )}
              </>
            )
          }

          {/* 모든 페이지 */}
          <button type="button" className="mobile_ham">
            <span>모바일메뉴열기</span>
          </button>
        </div>
      </div>
    </header>
  );
};

// 상단 로그인 메뉴
const HeaderLogin = (): JSX.Element => {
  const { showDialog } = useDialog();
  const navigate = useNavigate();
  const [tab, setTab] = useState<string>();
  const { member, getMemInfo, logout } = useMember();

  useEffect(() => {
    MemberAPI.getUser().then((res) => {
      // console.log('멤버정보 >> ', res.id);
      if (res) {
        if (res.id) {
          if (res.townDTO) {
            if (member) {
              getMemInfo(res);
              return;
            } else {
              getMemInfo(res);
              const message = "로그인 되었습니다.";
              const title = "";
              showDialog(OneButtonAlertDialog, {
                message,
                title,
              });
            }
          } else {
            getMemInfo(res);
            const message = "회원가입을 진행해 주세요.";
            const title = "";
            showDialog(OneButtonAlertDialog, {
              message,
              title,
            });
            navigate("/join_04/");
          }
        }
      } else {
        logout();

        if (member) {
            localStorage.removeItem("member");
            sessionStorage.removeItem("member");
            window.location.reload();
        }
      }
    });
  }, []);
  useEffect(() => {
    // console.log('로그인 멤버 정보', member);
  }, [member]);
  const handleLogin = () => {
    showDialog(LoginDialog, {
      title: "로그인",
    });
  };

  const handleLogout = () => {
    MemberAPI.logout().then((res) => {
      if (res == 200) {
        logout();
        const message = "로그아웃 되었습니다.";
        const title = "";
        navigate("/");
        showDialog(OneButtonAlertDialog, {
          message,
          title,
          onClose: () => {
            // 확인 버튼을 클릭하면 페이지 다시 로드
            window.location.reload();
          },
        });
      }
    });

    // deleteCookie()
    // navigate('/')
  };
  const handleJoin = () => {
    showDialog(LoginDialog, {
      title: "회원가입",
    });
  };

  const handleMypage = () => {
    sessionStorage.setItem("nowTab", String("마이페이지"));
    navigate("/myPage_02/");
  };
  const handleAdminPage = () => {
    navigate("/admin_02/");
  };
  return (
    <div className="header_login">
      <ul className="login_guest" style={{ display: member ? "none" : "flex" }}>
        <li>
          <a
            className="txt_blue"
            onClick={handleLogin}
            // onClick={clickTab}
            // className={`txt_blue ${tab === '로그인' ? 'selectTab' : ''}`}
          >
            <img src={login01} alt="로그인" />
            로그인
          </a>
        </li>
        <li>
          <a
            // onClick={clickTab}
            // className={`${tab === '회원가입' ? 'selectTab' : ''}`}
            className="tab"
            onClick={handleJoin}
          >
            <img src={login02} alt="회원가입" />
            회원가입
          </a>
        </li>
      </ul>
      <ul
        className="login_member"
        style={{ display: member ? "flex" : "none" }}
      >
        {member && member?.roles === "AMN" && (
          <li>
            <a className="txt_blue" onClick={handleAdminPage}>
              <img src={login01} alt="관리자 메뉴" />
              관리자 메뉴
            </a>
          </li>
        )}
        <li>
          <a
            onClick={handleLogout}
            // className={`${tab === '로그아웃' ? 'selectTab' : ''}`}
          >
            <img src={login03} alt="로그아웃" />
            로그아웃
          </a>
        </li>
        <li>
          <a
            className="txt_blue"
            // onClick={clickTab}
            // className={`txt_blue${tab === '마이페이지' ? 'selectTab' : ''}`}
            onClick={handleMypage}
          >
            <img src={login04} alt="마이페이지" />
            {/* <span className="header_alarm">1</span> */}
            {/* 내 알림 시 사용 */}
            마이페이지
          </a>
        </li>
      </ul>
    </div>
  );
};

// 네비게이션 바
const NavBar = () => {
  const navigate = useNavigate();
  const [tab, setTab] = useState<string>();
  const { showDialog } = useDialog();
  const { member } = useMember();
  const clickTab = (e: any) => {
    const newTab = e.target.innerText;
    setTab(newTab);

    sessionStorage.setItem("nowTab", newTab);
    const title = "";
    const message = "현재 준비중인 서비스입니다.";
    switch (newTab) {
      case "공지사항":
        navigate("/notice_02/", { replace: true });
        setTab(e.target.innerText);
        break;
      case "팝업 관리":
        navigate("/popup_02/", { replace: true });
        setTab(e.target.innerText);
        break;
      case "소통마당":
        navigate("/commu_02/", { replace: true });
        // setTab(e.target.innerText);
        break;
      case "참여마당":
        // showDialog(OneButtonAlertDialog, {
        // 	title,
        // 	message,
        // });
        navigate("/reward_02/", { replace: true });

        setTab(e.target.innerText);
        break;
      case "에너지전환 현황":
        // showDialog(OneButtonAlertDialog, {
        // 	title,
        // 	message,
        // });
        navigate("/reward_02/", { replace: true });

        setTab(e.target.innerText);
        break;
      case "에너지전환 활동미션":
        // showDialog(OneButtonAlertDialog, {
        // 	title,
        // 	message,
        // });
        navigate("/reward_02/mission-main/", { replace: true });
        setTab(e.target.innerText);
        break;
      case "에너지전환 챌린지":
        // showDialog(OneButtonAlertDialog, {
        // 	title,
        // 	message,
        // });
        navigate("/reward_02/challenge-main", { replace: true });
        setTab(e.target.innerText);
        break;
      // case '내 E 포인트':
      // 	// showDialog(OneButtonAlertDialog, {
      // 	// 	title,
      // 	// 	message,
      // 	// });
      // 	navigate('/reward_02/myEPoint', { replace: true });
      // 	setTab(e.target.innerText);
      // 	break;
      case "정보마당":
        // showDialog(OneButtonAlertDialog, {
        // 	title,
        // 	message,
        // });
        navigate("/info_02/", { replace: true });
        setTab(e.target.innerText);
        break;
      case "광주 에너지현황":
        // showDialog(OneButtonAlertDialog, {
        // 	title,
        // 	message,
        // });
        navigate("/info_02/", { replace: true });
        setTab(e.target.innerText);
        break;
      case "광주 에너지분석":
        // showDialog(OneButtonAlertDialog, {
        // 	title,
        // 	message,
        // });
        navigate("/info_02/analysis/", { replace: true });
        setTab(e.target.innerText);
        break;
      case "광주 태양광 에너지지도":
        // showDialog(OneButtonAlertDialog, {
        // 	title,
        // 	message,
        // });
        navigate("/info_02/map/", { replace: true });
        setTab(e.target.innerText);
        break;
      case "탄소계산기":
        // showDialog(OneButtonAlertDialog, {
        // 	title,
        // 	message,
        // });
        navigate("/info_02/cal/", { replace: true });
        setTab(e.target.innerText);
        break;
      case "소개마당":
        // showDialog(OneButtonAlertDialog, {
        // 	title,
        // 	message,
        // });
        navigate("/platform_02/", { replace: true });
        setTab(e.target.innerText);
        break;
      case "플랫폼":
        // showDialog(OneButtonAlertDialog, {
        // 	title,
        // 	message,
        // });
        navigate("/platform_02/", { replace: true });
        setTab(e.target.innerText);
        break;
      case "에너지전환마을":
        // showDialog(OneButtonAlertDialog, {
        // 	title,
        // 	message,
        // });
        navigate("/platform_02/energyConversionVillage/", { replace: true });
        setTab(e.target.innerText);
        break;
      case "에너지 용어사전":
        // showDialog(OneButtonAlertDialog, {
        // 	title,
        // 	message,
        // });
        navigate("/dictionary_02/", { replace: true });
        setTab(e.target.innerText);
        break;
    }
  };
  const handleMain = () => {
    sessionStorage.setItem("nowTab", String("홈"));
    navigate("/");
  };
  return (
    <>
      <a className="logo">
        <img
          src={energyLogoImg}
          alt="에너지전환마을 지원 플랫폼"
          onClick={handleMain}
        />
      </a>
      <nav id="gnb">
        <ul className="gnb_dep1">
          <li className="gnb_dep1_li">
            <a
              onClick={clickTab}
              className={`${tab === "공지사항" ? "selectTab" : ""}`}
            >
              공지사항
            </a>
            <ul className="gnb_dep2">
              <li>
                <a
                  onClick={clickTab}
                  className={`${tab === "공지사항" ? "selectTab" : ""}`}
                >
                  공지사항
                </a>
              </li>
              {member && member.roles && member.roles === "AMN" && (
                <li>
                  <a
                    onClick={clickTab}
                    className={`${tab === "팝업 관리" ? "selectTab" : ""}`}
                  >
                    팝업 관리
                  </a>
                </li>
              )}
            </ul>
          </li>
          <li className="gnb_dep1_li">
            <a
              onClick={clickTab}
              className={`${tab === "소개마당" ? "selectTab" : ""}`}
            >
              소개마당
            </a>
            <ul className="gnb_dep2">
              <li>
                <a
                  onClick={clickTab}
                  className={`${tab === "소개마당" ? "selectTab" : ""}`}
                >
                  플랫폼
                </a>
              </li>
              <li>
                <a
                  onClick={clickTab}
                  className={`${tab === "에너지전환마을" ? "selectTab" : ""}`}
                >
                  에너지전환마을
                </a>
              </li>
            </ul>
          </li>
          <li className="gnb_dep1_li">
            <a
              onClick={clickTab}
              className={`${tab === "소통마당" ? "selectTab" : ""}`}
            >
              소통마당
            </a>
          </li>
          <li className="gnb_dep1_li">
            <a
              onClick={clickTab}
              className={`${tab === "참여마당" ? "selectTab" : ""}`}
            >
              참여마당
            </a>
            <ul className="gnb_dep2">
              <li>
                <a
                  onClick={clickTab}
                  className={`${tab === "에너지전환 현황" ? "selectTab" : ""}`}
                >
                  에너지전환 현황
                </a>
              </li>
              <li>
                <a
                  onClick={clickTab}
                  className={`${
                    tab === "에너지전환 활동미션" ? "selectTab" : ""
                  }`}
                >
                  에너지전환 활동미션
                </a>
              </li>
              <li>
                <a
                  onClick={clickTab}
                  className={`${
                    tab === "에너지전환 챌린지" ? "selectTab" : ""
                  }`}
                >
                  에너지전환 챌린지
                </a>
              </li>
              {/* <li>
								<a
									onClick={clickTab}
									className={`${tab === '내 E 포인트' ? 'selectTab' : ''}`}
								>
									내 E 포인트
								</a>
							</li> */}
            </ul>
          </li>
          <li className="gnb_dep1_li">
            <a
              onClick={clickTab}
              className={`${tab === "정보마당" ? "selectTab" : ""}`}
            >
              정보마당
            </a>
            <ul className="gnb_dep2">
              <li>
                <a
                  onClick={clickTab}
                  className={`${tab === "광주 에너지현황" ? "selectTab" : ""}`}
                >
                  광주 에너지현황
                </a>
              </li>
              <li>
                <a
                  onClick={clickTab}
                  className={`${tab === "광주 에너지분석" ? "selectTab" : ""}`}
                >
                  광주 에너지분석
                </a>
              </li>
              <li>
                <a
                  onClick={clickTab}
                  className={`${
                    tab === "광주 태양광 에너지지도" ? "selectTab" : ""
                  }`}
                >
                  광주 태양광 에너지지도
                </a>
              </li>
              <li>
                <a
                  onClick={clickTab}
                  className={`${tab === "탄소계산기" ? "selectTab" : ""}`}
                >
                  탄소계산기
                </a>
              </li>
              <li>
                <a
                  onClick={clickTab}
                  className={`${tab === "에너지 용어사전" ? "selectTab" : ""}`}
                >
                  에너지 용어사전
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </nav>
    </>
  );
};

export default TopNavBar;
