import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import "./DictionaryPage.scss";
import SubLocation from "../../components/common/SubLocation";
import { useNavigate } from "react-router-dom";
import useMember from "../../hooks/useMember";
import header_logo from "../../assets/header_logo.png";
import testImgKo from "../../assets/testImgKo.png";
import DictionaryAPI, { DictionaryList } from "../../apis/GlossaryAPI";

const SubLocationList = [
  { href: "/platform_02/", text: "소개마당" },
  { href: "/commu_02/", text: "소통마당" },
  { href: "/reward_02/", text: "참여마당" },
  { href: "/info_02/", text: "정보마당" },
];
const SubLocationList2 = [
  { href: "/info_02/", text: "광주 에너지현황" },
  { href: "/info_02/analysis", text: "광주 에너지분석" },
  { href: "/info_02/map", text: "광주 태양광 에너지지도" },
  { href: "/info_02/cal", text: "탄소계산기" },
  { href: "/dictionary_02", text: "에너지 용어사전" },
];

const menuList = [
  {
    title: "소통마당",
    info: "소통마당은 마을 주민들이 에너지와 관련해 소통하고 활동하는 공간입니다.",
  },
  {
    title: "참여마당",
    info: "참여마당은 에너지와 관련된 미션과 챌린지에 참여해 각 마을의 에너지전환 활동으로 절약한 수치를 확인할 수 있습니다.",
  },
];
// 참여마당 > 에너지전환 현황
const DictionaryPage = () => {
  const { member } = useMember();
  const navigate = useNavigate();
  const [selectKey, setSelectKey] = useState<string>("전체");
  const [selectValue, setSelectValue] = useState<string>("");
  const [dictionaryList, setDictionaryList] = useState<DictionaryList[]>([]);
  const [searchButton, setSearchButton] = useState(false);
  useEffect(() => {
    DictionaryAPI.getList({
      ngNm: selectValue === "" ? undefined : selectValue,
      searchIndex: selectKey === "전체" ? undefined : selectKey,
    }).then((res) => {
      setDictionaryList(res);
    });
  }, [selectKey, searchButton]);

  const selectSearchKey = (key: string) => {
    // console.log(key);
    setSelectKey(key);
  };

  const enterKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key == "Enter") {
      setSearchButton(!searchButton);
    }
  };
  return (
    <main className="DictionaryPage">
      <h1 className="notext">전체 영역</h1>
      <nav id="lnb">
        <h2 className="notext">네비게이션 영역</h2>
        <SubLocation
          titleText1="정보마당"
          liList={SubLocationList}
          titleText2="에너지 용어사전"
          liList2={SubLocationList2}
        />
        <div className="lnb_txt">
          <h3 className="dictionaryTitle">에너지 용어사전</h3>
          <p>
            궁금한 에너지전환 관련 용어가 있으신가요?
            <br />
            알고싶으신 에너지전환 관련 용어를 이곳에서 검색해보세요.
          </p>
        </div>
      </nav>
      <article id="container">
        <h2 className="notext">컨텐츠 영역</h2>
        {/* 해당 페이지의 시작입니다 */}
        <div id="content">
          <div className="dictionaryDiv">
            <div className="writeButtonDiv"></div>
            <div className="dictionaryListDiv">
              <ul className="dictionaryListUl">
                <li>
                  <div className="search_box">
                    <input
                      type="text"
                      className="searchInput"
                      onChange={(e) => setSelectValue(e.target.value)}
                      onKeyDown={(e) => enterKeyDown(e)}
                    />
                    <button
                      type="button"
                      className="searchButton"
                      onClick={() => setSearchButton(!searchButton)}
                    >
                      검색
                    </button>
                    {/* <button type='button' className='writeButton' >추가</button> */}
                  </div>
                </li>
                <li className="searchKey">
                  <ul>
                    <li
                      className={selectKey === "전체" ? "selected" : ""}
                      onClick={() => selectSearchKey("전체")}
                    >
                      전체
                    </li>
                    <li
                      className={selectKey === "ㄱ" ? "selected" : ""}
                      onClick={() => selectSearchKey("ㄱ")}
                    >
                      ㄱ
                    </li>
                    <li
                      className={selectKey === "ㄴ" ? "selected" : ""}
                      onClick={() => selectSearchKey("ㄴ")}
                    >
                      ㄴ
                    </li>
                    <li
                      className={selectKey === "ㄷ" ? "selected" : ""}
                      onClick={() => selectSearchKey("ㄷ")}
                    >
                      ㄷ
                    </li>
                    <li
                      className={selectKey === "ㄹ" ? "selected" : ""}
                      onClick={() => selectSearchKey("ㄹ")}
                    >
                      ㄹ
                    </li>
                    <li
                      className={selectKey === "ㅁ" ? "selected" : ""}
                      onClick={() => selectSearchKey("ㅁ")}
                    >
                      ㅁ
                    </li>
                    <li
                      className={selectKey === "ㅂ" ? "selected" : ""}
                      onClick={() => selectSearchKey("ㅂ")}
                    >
                      ㅂ
                    </li>
                    <li
                      className={selectKey === "ㅅ" ? "selected" : ""}
                      onClick={() => selectSearchKey("ㅅ")}
                    >
                      ㅅ
                    </li>
                    <li
                      className={selectKey === "ㅇ" ? "selected" : ""}
                      onClick={() => selectSearchKey("ㅇ")}
                    >
                      ㅇ
                    </li>
                    <li
                      className={selectKey === "ㅈ" ? "selected" : ""}
                      onClick={() => selectSearchKey("ㅈ")}
                    >
                      ㅈ
                    </li>
                    <li
                      className={selectKey === "ㅊ" ? "selected" : ""}
                      onClick={() => selectSearchKey("ㅊ")}
                    >
                      ㅊ
                    </li>
                    <li
                      className={selectKey === "ㅋ" ? "selected" : ""}
                      onClick={() => selectSearchKey("ㅋ")}
                    >
                      ㅋ
                    </li>
                    <li
                      className={selectKey === "ㅌ" ? "selected" : ""}
                      onClick={() => selectSearchKey("ㅌ")}
                    >
                      ㅌ
                    </li>
                    <li
                      className={selectKey === "ㅍ" ? "selected" : ""}
                      onClick={() => selectSearchKey("ㅍ")}
                    >
                      ㅍ
                    </li>
                    <li
                      className={selectKey === "ㅎ" ? "selected" : ""}
                      onClick={() => selectSearchKey("ㅎ")}
                    >
                      ㅎ
                    </li>
                    <li
                      className={selectKey === "A" ? "selected" : ""}
                      onClick={() => selectSearchKey("A")}
                    >
                      A~Z
                    </li>
                    <li
                      className={selectKey === "0" ? "selected" : ""}
                      onClick={() => selectSearchKey("0")}
                    >
                      0~9
                    </li>
                  </ul>
                </li>
              </ul>
              <ul className="searchList">
                {dictionaryList.length !== 0 ? (
                  dictionaryList.map((list, index) => (
                    <>
                      <li>
                        <p className="name" key={index}>
                          {list.ngNm}
                        </p>
                        <p>{list.info}</p>
                      </li>
                    </>
                  ))
                ) : (
                  <p
                    style={{
                      fontSize: "18px",
                      color: "#666",
                      marginTop: "40px",
                    }}
                  >
                    등록된 용어가 없습니다.
                  </p>
                )}

                {/* <li>
                                    <p className='name'>에너지 정책</p>
                                    <p >에너지의 생산과 공급, 에너지의 전환, 저장, 분해, 사용 그리고 국내적으로 추정된 에너지 자원의 이용 가능성과 예상되는 전체 에너지 수요와 측정방식에 관계된 국내 정책(혹은국제정책)의 한분야</p>
                                </li>
                                <li>
                                    <p className='name'>에너지 전환</p>
                                    <p >에너지 형태의 물리적 상황에서 아무런 변화도 주지않고 에너지를 재생 혹은 생산하는것을 말함</p>
                                </li> */}
              </ul>
            </div>
          </div>
        </div>
        {/* 해당 페이지의 종료입니다 */}
      </article>
    </main>
  );
};

export default DictionaryPage;
