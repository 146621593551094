import React, { useEffect, useState } from 'react';
import SubLocation from '../../components/common/SubLocation';
import './Main_PopupManagement.scss';
import { useNavigate } from 'react-router-dom';
import { useDialog } from '../../dialog/DialogProvider';
import useMember from '../../hooks/useMember';
import moment from 'moment';
import noList from '../../assets/noList.png';
import test01 from '../../assets/character01.png';
import test02 from '../../assets/character02.png';
import test03 from '../../assets/character03.png';
import test04 from '../../assets/character04.png';
import test05 from '../../assets/character05.png';
import test06 from '../../assets/character06.png';
import { PopupListInfo } from '../../models/PopupManager';
import PopupAPI from '../../apis/PopupAPI';
import TwoButtonAlertDialog from '../../dialog/TwoButtonAlertDialog';
import Pagination from '../../components/common/Pagination';

// 소통마당 메인
const SubLocationList = [
    { href: '/platform_02/', text: '소개마당' },
    { href: '/commu_02/', text: '소통마당' },
    { href: '/reward_02/', text: '참여마당' },
    { href: '/info_02/', text: '정보마당' },
];

const Main_PopupManagement = () => {

    return (
        <main className="Main_PopupManagement">
            <h1 className="notext">전체 영역</h1>
            <Nav />
            <article id="container">
                <h2 className="notext">컨텐츠 영역</h2>
                {/* 해당 페이지의 시작입니다 */}
                <div id="content">
                    <PopupListComponent />
                </div>
                {/* 해당 페이지의 종료입니다 */}
            </article>
        </main>
    );
};

const Nav = () => {
    return (
        <nav id="lnb">
            <h2 className="notext">네비게이션 영역</h2>
            <SubLocation titleText1="공지사항" liList={SubLocationList} />
            <div className="lnb_txt">
                <h3>팝업 관리</h3>
                {/* <p>모든 마을의 최신 현황을 확인하고 내 마을에 들어가 소통해보세요</p> */}
            </div>
        </nav>
    );
};

// 공지사항 리스트
// SwiperCore.use([Navigation, Pagination, Autoplay]);
// const popupList = [
//     { id: 6, img: test01, content: '팝업 테스트 01', use: 'Y', date: '2024.05.04' },
//     { id: 2, img: test02, content: '팝업 테스트 02', use: 'Y', date: '2024.05.22' },
//     { id: 5, img: test03, content: '팝업 테스트 03', use: 'Y', date: '2024.05.05' },
//     { id: 3, img: test04, content: '팝업 테스트 04', use: 'Y', date: '2024.05.14' },
//     { id: 4, img: test05, content: '팝업 테스트 05', use: 'Y', date: '2024.05.05' },
//     { id: 1, img: test06, content: '팝업 테스트 06', use: 'Y', date: '2024.05.27' },
// ]
const PopupListComponent = () => {
    const navigate = useNavigate();
    const { member } = useMember();
    const { showDialog } = useDialog();
    const [page, setPage] = useState<number>(1);
    const [count, setCount] = useState(0);
    const [popupList, setPopupList] = useState([] as PopupListInfo[])
    const [reLoading, setReLoading] = useState(false);
    const [selectStatus, setSelectStatus] = useState<string>('');

    // 권한관리
	useEffect(() => {
		if (!member || member.roles !== 'AMN') {
			navigate('/');
		}
	}, []);

    useEffect(() => {
        sessionStorage.removeItem('popupN');
        PopupAPI.getPopupList({
            page: page,
            pageSize: 5,
            status: selectStatus === '' ? undefined : selectStatus
        })
            .then((res) => {
                if (res) {
                    // console.log(res);
                    if (res[0]) {
                        setPopupList(res);
                        if (res[0].totalCnt) {
                            setCount(res[0].totalCnt);
                        }
                    } else {
                        setPopupList([]);
                        setCount(0)
                    }
                }
            })
    }, [page, reLoading, selectStatus])

    const handlePopupModify = (npuUid: number) => {
        sessionStorage.setItem('popupN', String(npuUid));
        navigate('/popup_02/popup_04-modify');
    }

    const handlePageChange = (page: number) => {
        setPage(page);
    };

    const handleDetail = () => {
        navigate('/popup_02/popup_04-detail')
    }


    const handlePopupDelete = (npuUid: number) => {
        const title = '팝업 관리';
        const message = '팝업을 삭제하시겠습니까?';
        const action = 'ok'
        showDialog(TwoButtonAlertDialog, {
            title,
            message,
            action,
            handleDialogResult(result) {
                if (result === '확인') {
                    PopupAPI.deletePopup({
                        npuUid: npuUid
                    })
                        .then((res) => {
                            if (res.data.type == 1) {
                                // console.log(res)
                                setReLoading(!reLoading);
                            }
                        })
                }
            },
        });
    }

    const handleSelectStatue = (status: string) => {
        setPage(1);
        setSelectStatus(status);
    }
    return (
        <div className="village_cont mt40">
            <div className="cont_content mt25 village_table">
                <div style={{ marginBottom: '20px' }}>
                    <button onClick={handleDetail} style={{ padding: '0px 20px', borderRadius: '5px', backgroundColor: '#1d5d9b', height: '50px', fontSize: '1.25rem', color: '#fff' }}>등록하기</button>
                    <select
                        style={{ marginLeft: '10px', padding: '0px 20px', height: '50px', marginBottom: '5px', borderRadius: '5px', fontSize: '1.25rem' }}
                        value={selectStatus} onChange={(e) => handleSelectStatue(e.target.value)}
                    >
                        <option value=''>선택</option>
                        <option value='Y'>사용중</option>
                        <option value='N'>미사용중</option>
                    </select>
                </div>
                <ul className="thead pa20">
                    <li>이미지</li>
                    <li>내용</li>
                    <li>사용여부</li>
                    <li>수정일</li>
                    <li>관리</li>
                </ul>
                <ul className="tbody">
                    {popupList.length !== 0 ?
                        popupList.map((list, index) => (
                            <li className="pa20" key={index} style={{ cursor: 'default' }}>
                                <p>
                                    {list.fileList[0] ?
                                        <img style={{ width: '50px', height: '50px' }} src={`/api/attach/getFile?savedPath=${list.fileList[0].savePath}`} alt="팝업 이미지" />

                                        :
                                        '-'
                                    }
                                </p>
                                <p style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{list.title ? list.title : '-'}</p>
                                <p style={{ color: `${list.status === 'Y' ? 'blue' : 'red'}`, fontWeight: '600' }}>{`${list.status === 'Y' ? '사용중' : '미사용중'}`}</p>
                                <p>{list.updDt == null ? list.crtDt.slice(0, 10) : list.updDt.slice(0, 10)}</p>
                                <p>
                                    <button type='button' className='modifyButton' onClick={() => handlePopupModify(list.npuUid)}>수정</button>
                                    <button type='button' className='deleteButton' onClick={() => handlePopupDelete(list.npuUid)}>삭제</button>
                                </p>
                            </li>
                        ))
                        :
                        <li className="pa20">
                            <p>-</p>
                            <p>-</p>
                            <p>-</p>
                            <p>-</p>
                            <p>-</p>
                        </li>
                    }
                </ul>
            </div>
            <Pagination
                page={Number(page)}
                onPageChange={handlePageChange}
                // totalPageCount={Math.ceil(count / 8)}
                totalPageCount={Math.ceil(count / 10)}
            />
        </div>
    );
};

export default Main_PopupManagement;
