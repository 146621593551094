import React, { useEffect, useState } from 'react';
import SubLocation from '../../components/common/SubLocation';
import './EnergyAnalysisPage.scss';
import img_grahp from '../../assets/img_grahp.png';
import img_grahp_2 from '../../assets/img_grahp_2.png';
import img_energy from '../../assets/img_energy.png';
import ChartComponent_bar from '../../components/common/ChartComponent_bar';
import Piechart from '../../components/common/ChartComponent_pie';
import { ResponsiveContainer } from 'recharts';
import ChartComponent_bar_energy from '../../components/common/ChartComponent_bar_energy';
import ChartAPI from '../../apis/ChartAPI';
import { BarChartDataList } from '../../models/ChartList';
import { BugguSelect, DongguSelect, GwangsanguSelect, NamguSelect, SeoguSelect } from '../../models/EnergyDataSelect';
import useMember from '../../hooks/useMember';

// 정보마당 > 광주 에너지분석
const SubLocationList = [
    { href: '/platform_02/', text: '소개마당' },
    { href: '/commu_02/', text: '소통마당' },
    { href: '/reward_02/', text: '참여마당' },
    { href: '/info_02/', text: '정보마당' },
];

const SubLocationList2 = [
	{ href: '/info_02/', text: '광주 에너지현황' },
	{ href: '/info_02/analysis', text: '광주 에너지분석' },
	{ href: '/info_02/map', text: '광주 태양광 에너지지도' },
	{ href: '/info_02/cal', text: '탄소계산기' },
	{ href: '/dictionary_02', text: '에너지 용어사전' },
];

const EnergyAnalysisPage = () => {
	const { member } = useMember();
	const [tab, setTab] = useState<number>(0);
	const [guSelect, setGuSelect] = useState<string>(member ? member.gu : '전체');
	const [dongSelect, setDongSelect] = useState<string>('전체');
	const [sYyyySelect, setSYyyySelect] = useState<string>('2015');
	const [eYyyySelect, setEYyyySelect] = useState<string>('2023');
	const [category, setCategory] = useState<string>('전체');
	const [type, setType] = useState('전체');
	const [searchButton, setSearchButton] = useState(false);

	// useEffect(() => {
	// 	const intervalId = setInterval(() => {
	// 		const guList = [
	// 			"서구",
	// 			"동구",
	// 			"광산구",
	// 			"남구",
	// 			"북구"
	// 		]
	// 		setGuSelect(guList[Math.floor(Math.random() * 4)]);
	// 		setSearchButton(prevSearchButton => !prevSearchButton);

	// 		console.log("reresfdgg", Math.floor(Math.random() * 4))
	// 	}, 3000)
	// 	return () => clearInterval(intervalId);
	// }, [])
	useEffect(() => {
		if (member) {
			setGuSelect(member.gu);
			setDongSelect(member.dong);
		}
	}, [])

	const handleChartDongSelect = (value: string) => {
		// console.log("와냐", value)
		setDongSelect(value);
	}
	const handleGuSelect = (e: any) => {
		// console.log("구 선택 >>>> ", e.target.value);
		setDongSelect('전체');
		setGuSelect(e.target.value);
	}
	const handleDongSelect = (e: any) => {
		// console.log("동 선택 >>>> ", e.target.value);
		setDongSelect(e.target.value);
	}
	const handleSYyyySelect = (e: any) => {
		// console.log("시작 연도별 선택 >>>> ", e.target.value);
		setSYyyySelect(e.target.value);
	}
	const handleEYyyySelect = (e: any) => {
		// console.log("끝 연도별 선택 >>>> ", e.target.value);
		setEYyyySelect(e.target.value);
	}
	const handleCategorySelect = (e: any) => {
		// console.log("카테고리 선택 >>>> ", e.target.value);
		setCategory(e.target.value);
	}
	const handleTypeSelect = (e: any) => {
		// console.log("타입 선택 >>>> ", e.target.value);
		setType(e.target.value);
	}
	return (
		<main className="EnergyAnalysisPage">
			<h1 className="notext">전체 영역</h1>
			<nav id="lnb">
				<h2 className="notext">네비게이션 영역</h2>
				<SubLocation
					titleText1="정보마당"
					liList={SubLocationList}
					titleText2="광주 에너지분석"
					liList2={SubLocationList2}
				/>
				<div className="lnb_txt">
					<h3>광주 에너지분석</h3>
					{/* <CommSelect/> */}
					<p>
						광주광역시 자치구와 행정동의 에너지를 분석한 결과를 확인해보세요.{' '}
					</p>
				</div>
			</nav>
			<article id="container">
				<h2 className="notext">컨텐츠 영역</h2>
				{/* 해당 페이지의 시작입니다 */}
				<div id="content">
					<div className="wrapper">
						{/*검색탭 시작*/}
						<div className="search_box pa20">
							<div className="select mt10">
								<h1>행정동 선택</h1>
								<div className="select_box01 mt10">
									<select onChange={handleGuSelect} value={guSelect}>
										<option value='전체'>구 전체</option>
										<option value='동구'>동구</option>
										<option value='서구'>서구</option>
										<option value='남구'>남구</option>
										<option value='북구'>북구</option>
										<option value='광산구'>광산구</option>
									</select>
									<select onChange={handleDongSelect} value={dongSelect} disabled={guSelect === '전체' ? true : false}>
										<option value="전체">동 전체</option>
										{/* {guSelect === '전체' &&
											<option value="전체">동 전체</option>
										} */}
										{guSelect === '동구' &&
											DongguSelect.map((list, index) => (
												<option key={index} value={list.area}>{list.area}</option>
											))
										}
										{guSelect === '서구' &&
											SeoguSelect.map((list, index) => (
												<option key={index} value={list.area}>{list.area}</option>
											))
										}
										{guSelect === '남구' &&
											NamguSelect.map((list, index) => (
												<option key={index} value={list.area}>{list.area}</option>
											))
										}
										{guSelect === '북구' &&
											BugguSelect.map((list, index) => (
												<option key={index} value={list.area}>{list.area}</option>
											))
										}
										{guSelect === '광산구' &&
											GwangsanguSelect.map((list, index) => (
												<option key={index} value={list.area}>{list.area}</option>
											))
										}
									</select>
								</div>
							</div>
							<div className="select mt10">
								<h1>연도별</h1>
								<div className="select_box02 mt10">
									<select value={sYyyySelect} onChange={handleSYyyySelect}>
										<option value='2015'>2015</option>
										<option value='2016'>2016</option>
										<option value='2017'>2017</option>
										<option value='2018'>2018</option>
										<option value='2019'>2019</option>
										<option value='2020'>2020</option>
										<option value='2021'>2021</option>
										<option value='2022'>2022</option>
										<option value='2023'>2023</option>
										{/* <option value='2024'>2024</option> */}
									</select>
									<p>~</p>
									<select value={eYyyySelect} onChange={handleEYyyySelect}>
										<option value='2015'>2015</option>
										<option value='2016'>2016</option>
										<option value='2017'>2017</option>
										<option value='2018'>2018</option>
										<option value='2019'>2019</option>
										<option value='2020'>2020</option>
										<option value='2021'>2021</option>
										<option value='2022'>2022</option>
										<option value='2023'>2023</option>
										{/* <option value='2024'>2024</option> */}
									</select>
								</div>
								<div className="select01 mt10">
									<h1>용도</h1>
									<select onChange={handleCategorySelect} disabled={dongSelect === '전체' ? true : false}>
										<option value='전체'>전체</option>
										<option value='공공'>공공</option>
										<option value='교육'>교육</option>
										<option value='교통'>교통</option>
										<option value='상업'>상업</option>
										<option value='업무'>업무</option>
										<option value='종교'>종교</option>
										<option value='주거'>주거</option>
										<option value='문화복지'>문화복지</option>
										<option value='승용차'>승용차</option>
										<option value='승합차'>승합차</option>
										<option value='특수차'>특수차</option>
										<option value='화물차'>화물차</option>
										<option value='기타'>기타</option>
									</select>
								</div>
							</div>
							<div className="select01 mt10">
								<h1>에너지원</h1>
								<select onChange={handleTypeSelect} disabled={category === '전체' ? true : false}>
									{category === '승용차' || category === '승합차' || category === '특수차' || category === '화물차'
										?
										<>
											<option value='전체'>전체</option>
											<option value="휘발유">휘발유</option>
											<option value="경유">경유</option>
											<option value="LPG">LPG</option>
											<option value="기타">기타</option>
										</>
										:
										<>
											<option value='전체'>전체</option>
											<option value='전력'>전력</option>
											<option value='도시가스'>도시가스</option>
											<option value='지역난방'>지역난방</option>
										</>
									}
								</select>
							</div>
							<div className="button mt25">
								<button type="button" onClick={() => setSearchButton(!searchButton)}>조회</button>
							</div>
						</div>
						{/*검색탭 종료*/}
						<div className="tab_box">
							<div className="tabs">
								<button
									type="button"
									className={`tab ${tab == 0 ? 'tab_active' : 'non_active'}`}
									onClick={() => setTab(0)}
								>
									에너지 소비량
								</button>
								<button
									type="button"
									className={`tab ${tab == 1 ? 'tab_active' : 'non_active'}`}
									onClick={() => setTab(1)}
								>
									온실가스 배출량(전체 기준)
								</button>
								<button
									type="button"
									className={`tab ${tab == 2 ? 'tab_active' : 'non_active'}`}
									onClick={() => setTab(2)}
								>
									인구수 원단위(1인 기준)
								</button>
							</div>
							{/*에너지소비량 탭 시작*/}
							{tab == 0 &&
								<EnergyTab
									guSelect={guSelect}
									dongSelect={dongSelect}
									sYyyySelect={sYyyySelect}
									eYyyySelect={eYyyySelect}
									category={category}
									type={type}
									searchButton={searchButton}
									handleChartDongSelect={handleChartDongSelect}
									tab={tab}
								/>}
							{/*에너지소비량 탭 끝*/}
							{/*온실가스 배출량 탭 시작*/}
							{tab == 1 &&
								<GasTab
									guSelect={guSelect}
									dongSelect={dongSelect}
									sYyyySelect={sYyyySelect}
									eYyyySelect={eYyyySelect}
									category={category}
									type={type}
									searchButton={searchButton}
									handleChartDongSelect={handleChartDongSelect}
									tab={tab}
								/>}
							{/*온실가스 배출량 탭 끝*/}
							{/*인구수 원단위 탭 시작*/}
							{tab == 2 &&
								<PopulationTab
									guSelect={guSelect}
									dongSelect={dongSelect}
									sYyyySelect={sYyyySelect}
									eYyyySelect={eYyyySelect}
									category={category}
									type={type}
									searchButton={searchButton}
									handleChartDongSelect={handleChartDongSelect}
									tab={tab}
								/>}
							{/*인구수 원단위 탭 끝*/}
						</div>
					</div>
				</div>
				{/* 해당 페이지의 종료입니다 */}
			</article>
		</main >
	);
};
interface EnergyTabParams {
	guSelect: string;
	dongSelect: string;
	sYyyySelect: string;
	eYyyySelect: string;
	category: string;
	type: string;
	searchButton: boolean;
	handleChartDongSelect: (value: string) => void;
	tab: number;
}
const EnergyTab = ({ guSelect, dongSelect, sYyyySelect, eYyyySelect, category, type, searchButton, handleChartDongSelect, tab }: EnergyTabParams) => {
	const [barChartData, setBarChartData] = useState<BarChartDataList>();
	const { member } = useMember();
	const [myDong, setMyDong] = useState<string>();
	const [myRank, setMyRank] = useState<number>();

	useEffect(() => {
		ChartAPI.getBarChartDataList({
			areaUpper: guSelect,
			area: dongSelect,
			buldCtgry: category,
			type: type,
			syyyy: sYyyySelect,
			eyyyy: eYyyySelect
		}).then((res) => {
			// console.log("바 차트데이터", res)
			// console.log("sadasdasd", dongSelect)
			setBarChartData(res)
			// setMyRank(res.rank);
			// setMyDong(dongSelect);
			// if (member) {
			// 	setMyDong(member.dong);
			// }
		})
	}, [searchButton])

	useEffect(() => {
		if (member) {
			ChartAPI.getBarChartDataList({
				areaUpper: member?.gu,
				area: member?.dong,
				buldCtgry: '전체',
				type: '전체',
				syyyy: '2015',
				eyyyy: '2024'
			}).then((res) => {
				setMyDong(member.dong);
				setMyRank(res.rank);
				setBarChartData(res)
			})
		}
	}, [])


	return (
		<div className="tab_content mt40" data-id={0}>
			{/* 2024.07.02 주석처리  */}
			{/* {member &&
				<div className="energy_consumption">
					<img src={img_energy} alt="에너지소비량이미지" />
					<div className="txt pa30">
						<p>
							<span>{myDong}</span>의 에너지소비량은
						</p>
						<p>
							97개 행정동 중 <span className="txt_blue">{myRank}위</span>입니다.
						</p>
					</div>
				</div>
			} */}
			<div className="graph_wrap pa30 mt40">
				<h1>행정동별 에너지소비량 현황</h1>
				<div className="img_box pa20 mt10">
					<ChartComponent_bar data={barChartData} dongSelect={dongSelect} category={category} handleChartDongSelect={handleChartDongSelect} tab={tab} />
				</div>
				{/* <Piechart />
					<img
						src={img_grahp}
						alt="행정동별에너지소비량현황그래프"
						style={{ width: '100%' }}
					/> */}
				<p className='mt10' style={{ textAlign: 'end' }}>단위: TOE</p>
			</div>
			<div className="graph_wrap pa30 mt25">
				<h1>연도별 에너지소비량 현황</h1>
				<div className="img_box pa20 mt10">
					<ChartComponent_bar_energy data={barChartData} category={category} tab={tab} />
					{/* <img
						src={img_grahp_2}
						alt="연도별에너지소비량현황그래프"
						style={{ width: '100%' }}
					/> */}
				</div>
				<p className='mt10' style={{ textAlign: 'end' }}>단위: TOE</p>
			</div>
		</div>
	);
};
interface GasTabParams {
	guSelect: string;
	dongSelect: string;
	sYyyySelect: string;
	eYyyySelect: string;
	category: string;
	type: string;
	searchButton: boolean;
	handleChartDongSelect: (value: string) => void;
	tab: number;
}
const GasTab = ({ guSelect, dongSelect, sYyyySelect, eYyyySelect, category, type, searchButton, handleChartDongSelect, tab }: GasTabParams) => {
	const [barChartData, setBarChartData] = useState<BarChartDataList>();
	const [myDong, setMyDong] = useState<string>();
	const [myRank, setMyRank] = useState<number>();
	const { member } = useMember();

	useEffect(() => {
		ChartAPI.getBarChartGasDataList({
			areaUpper: guSelect,
			area: dongSelect,
			buldCtgry: category,
			type: type,
			syyyy: sYyyySelect,
			eyyyy: eYyyySelect
		}).then((res) => {
			// console.log("바 차트데이터", res)
			setBarChartData(res)
			// setMyRank(res.rank);
			// setMyDong(dongSelect);
		})
	}, [searchButton])

	useEffect(() => {
		if (member) {
			ChartAPI.getBarChartDataList({
				areaUpper: member?.gu,
				area: member?.dong,
				buldCtgry: '전체',
				type: '전체',
				syyyy: '2015',
				eyyyy: '2024'
			}).then((res) => {
				setMyDong(member.dong);
				setMyRank(res.rank);
			})
		} else {

		}

	}, [])
	return (
		<div className="tab_content mt40" data-id={1}>
			{/* 2024.07.08 주석처리  */}
			{/* {member &&
				<div className="energy_consumption">
					<img src={img_energy} alt="에너지소비량이미지" />
					<div className="txt pa30">
						<p>
							<span>{myDong}</span>의 에너지소비량은
						</p>
						<p>
							97개 행정동 중 <span className="txt_blue">{myRank}위</span>입니다.
						</p>
					</div>
				</div>
			} */}
			<div className="graph_wrap pa30 mt40">
				<h1>행정동별 온실가스 배출량 현황</h1>
				<div className="img_box pa20 mt10">
					<ChartComponent_bar data={barChartData} dongSelect={dongSelect} category={category} handleChartDongSelect={handleChartDongSelect} tab={tab} />
				</div>
				{/* <img
					src={img_grahp}
					alt="행정동별에너지소비량현황그래프"
					style={{ width: '100%' }}
				/> */}
				<p className='mt10' style={{ textAlign: 'end' }}>단위: tonCO2eq</p>
			</div>
			<div className="graph_wrap pa30 mt25">
				<h1>연도별 온실가스 배출량 현황</h1>
				<div className="img_box pa20 mt10">
					<ChartComponent_bar_energy data={barChartData} category={category} tab={tab} />
					{/* <img
						src={img_grahp_2}
						alt="연도별에너지소비량현황그래프"
						style={{ width: '100%' }}
					/> */}
				</div>
				<p className='mt10' style={{ textAlign: 'end' }}>단위: tonCO2eq</p>
			</div>
		</div>
	);
};
interface PopulationTabParams {
	guSelect: string;
	dongSelect: string;
	sYyyySelect: string;
	eYyyySelect: string;
	category: string;
	type: string;
	searchButton: boolean;
	handleChartDongSelect: (value: string) => void;
	tab: number;
}
const PopulationTab = ({ guSelect, dongSelect, sYyyySelect, eYyyySelect, category, type, searchButton, handleChartDongSelect, tab }: PopulationTabParams) => {
	const [barChartData, setBarChartData] = useState<BarChartDataList>();
	const [myDong, setMyDong] = useState<string>();
	const [myRank, setMyRank] = useState<number>();
	const { member } = useMember();

	useEffect(() => {
		ChartAPI.getBarChartPopanalyzeDataList({
			areaUpper: guSelect,
			area: dongSelect,
			buldCtgry: category,
			type: type,
			syyyy: sYyyySelect,
			eyyyy: eYyyySelect
		}).then((res) => {
			// console.log("ㅁㅁ바 차트데이터", res)
			setBarChartData(res)
		})
	}, [searchButton])

	useEffect(() => {
		if (member) {
			ChartAPI.getBarChartDataList({
				areaUpper: member?.gu,
				area: member?.dong,
				buldCtgry: '전체',
				type: '전체',
				syyyy: '2015',
				eyyyy: '2024'
			}).then((res) => {
				setMyDong(member.dong);
				setMyRank(res.rank);
			})
		} else {

		}

	}, [])

	return (
		<div className="tab_content mt40" data-id={2}>
			{/* 2024.07.08 주석처리  */}
			{/* {member &&
				<div className="energy_consumption">
					<img src={img_energy} alt="에너지소비량이미지" />
					<div className="txt pa30">
						<p>
							<span>{myDong}</span>의 에너지소비량은
						</p>
						<p>
							97개 행정동 중 <span className="txt_blue">{myRank}위</span>입니다.
						</p>
					</div>
				</div>
			} */}
			<div className="graph_wrap pa30 mt40">
				<h1>행정동별 인구수 원단위 현황</h1>
				<div className="img_box pa20 mt10">
					<ChartComponent_bar data={barChartData} dongSelect={dongSelect} category={category} handleChartDongSelect={handleChartDongSelect} tab={tab} />
					{/* <img
						src={img_grahp}
						alt="행정동별에너지소비량현황그래프"
						style={{ width: '100%' }}
					/> */}
				</div>
				<p className='mt10' style={{ textAlign: 'end' }}>단위: tonCO2eq/인</p>
			</div>
			<div className="graph_wrap pa30 mt25">
				<h1>연도별 인구수 원단위 현황</h1>
				<div className="img_box pa20 mt10">
					<ChartComponent_bar_energy data={barChartData} category={category} tab={tab} />
					{/* <img
						src={img_grahp_2}
						alt="연도별에너지소비량현황그래프"
						style={{ width: '100%' }}
					/> */}
				</div>
				<p className='mt10' style={{ textAlign: 'end' }}>단위: tonCO2eq/인</p>
			</div>
		</div>
	);
};
export default EnergyAnalysisPage;
