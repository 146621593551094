import React, { useEffect, useState } from 'react';
import SubLocation from '../../../components/common/SubLocation';
import ic_cam from '../../../assets/ic_cam.png';
import test01 from '../../../assets/test01.png';
import test02 from '../../../assets/close-button.png';
import { useNavigate } from 'react-router';
import { SubmitHandler, useForm } from 'react-hook-form';
import { formVillageCommu } from '../../../models/VillageCommuInfo';
import VillageCommuAPI from '../../../apis/VillageCommuAPI';
import { useDialog } from '../../../dialog/DialogProvider';
import SubmitSuccessDialog from '../../../dialog/common/SubmitSuccessDialog';
import SubmitFailDialog from '../../../dialog/common/SubmitFailDialog';
import { isRegularExpressionLiteral } from 'typescript';
import useMember from '../../../hooks/useMember';
import OneButtonAlertDialog from '../../../dialog/OneButtonAlertDialog';
import './Write_VillageCommuPage.scss'

// 소통마당 > 마을소통 > 등록하기
const SubLocationList = [
    { href: '/platform_02/', text: '소개마당' },
    { href: '/commu_02/', text: '소통마당' },
    { href: '/reward_02/', text: '참여마당' },
    { href: '/info_02/', text: '정보마당' },
];

const Write_VillageCommuPage = () => {
	return (
		<main className='Write_VillageCommuPage'>
			<h1 className="notext">전체 영역</h1>
			<Nav />
			<article id="container">
				<h2 className="notext">컨텐츠 영역</h2>
				{/* 해당 페이지의 시작입니다 */}
				<div id="content">
					<Form />
				</div>
				{/* 해당 페이지의 종료입니다 */}
			</article>
		</main>
	);
};

// nav
const Nav = () => {
	return (
		<nav id="lnb">
			<h2 className="notext">네비게이션 영역</h2>
			<SubLocation titleText1="소통마당" liList={SubLocationList} />
			<div className="lnb_txt">
				<h3>마을소통 등록하기</h3>
			</div>
		</nav>
	);
};

// 등록form
const Form = () => {
	const { register, handleSubmit, watch, setFocus } = useForm<formVillageCommu>({});
	const { showDialog } = useDialog();
	// const [category, setCategory] = useState('');
	// const [content, setContent] = useState('');
	const [allInput, setAllInput] = useState(false);
	const [contentError, setContentError] = useState("");
	const townId = sessionStorage.getItem('town');
	const { member } = useMember();
	// 이미지 파일
	const [previewImages, setPreviewImages] = useState<string[]>([]);
	const [uploadFiles, setUploadFiles] = useState([] as File[]);
	const [categoryError, setCategoryError] = useState("");
	// pdf파일
	const [pdfFiles, setPdfFiles] = useState([] as File[]);
	type PreviewFile = string | { file: File; preview: string; name: string };
	const [previewFiles, setPreviewFiles] = useState<PreviewFile[]>([]);

	const navigate = useNavigate();

	// 취소
	const handleCancle = () => {
		navigate(-1);
	};

	// // 필수 사항 모두 입력 시 'on' 클래스 추가됨
	// useEffect(() => {
	// 	const checkInputs = () => {
	// 		return category.trim() !== '' && content.trim() !== '';
	// 	};
	// },[category, content])

	// const updSubmitBtn = () => {
	// 	if(category !== "" && content !== ""){
	// 		setAllInput(true);
	// 	}
	// };

	const cate = watch('category');
	const cont = watch('content');

	useEffect(() => {
		if (cate && cont) {
			setAllInput(true);
		} else {
			setAllInput(false)
		}
	}, [cate, cont]);

	// // console.log("카테고리", category);
	// // console.log("내용", content);
	// console.log("cate", cate);
	// console.log("cont", cont);

	// 파일 첨부
	const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		// // console.log('files ::::::: ', event.target.files);
		const files = event.target.files;
		if (!files) return;
		const selectedFiles = Array.from(files);

		setPdfFiles((prevFiles: File[]) => {
			const newFiles = [...prevFiles];
			selectedFiles.slice(0, 5).forEach((file) => newFiles.push(file));
			// // console.log('filesToSubmit ::::::::::::::::: ', uploadFiles);
			return newFiles;
		});

		// 미리보기 파일 추가
		const filesToSubmit = selectedFiles.slice(0, 5);
		const newPreviewFiles = filesToSubmit.map((file) => {
			// 파일명 추출
			const fileName = file.name;
			return {
				file,
				preview: URL.createObjectURL(file),
				name: fileName,
			};
		});

		setPreviewFiles((prevFiles: PreviewFile[]) => [...prevFiles, ...newPreviewFiles]);
	}

	// 파일 미리보기 삭제
	const handleRemoveFilePreview = (index: number) => {
		setPreviewFiles((prevFiles) => {
			const newFiles = [...prevFiles];
			newFiles.splice(index, 1);
			return newFiles;
		});
		setPdfFiles((prevFiles) => {
			const newFiles = [...prevFiles];
			newFiles.splice(index, 1);
			return newFiles;
		});
	};


	// 사진 첨부
	const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const files = event.target.files;
		if (!files) return;
		const selectedImages = Array.from(files);
		// 이미지를 추가할 때 previewImages 배열의 길이가 5를 넘어가지 않도록 처리
		if (previewImages.length + selectedImages.length > 5) {
			// alert('사진은 최대 5개 까지 업로드할수있다.');
			const title = '사진 첨부';
			const message = '사진은 최대 5장까지 업로드할 수 있습니다.';
			showDialog(OneButtonAlertDialog, { title, message });
			return;
		}
		if (selectedImages && selectedImages[0].size > 15000000) {
			const title = '';
			const message = '파일 크기는 최대 15MB까지만 가능합니다.';
			showDialog(OneButtonAlertDialog, {
				title,
				message,
			});
			return;
		}
		if (!(selectedImages[0].type === 'image/png' || selectedImages[0].type === 'image/jpeg')) {
			const title = '';
			const message = '파일 형식은 png, jpg 만 등록 가능합니다.';
			showDialog(OneButtonAlertDialog, {
				title,
				message,
			});
			return;
		}
		setUploadFiles((prevImages: any) => {
			const newImages = [...prevImages];
			selectedImages.slice(0, 5).forEach((image) => newImages.push(image));
			// // console.log('imagesToSubmit ::::::::::::::::: ', uploadFiles);
			return newImages;
		});
		// 미리보기 이미지 추가
		const imagesToSubmit = selectedImages.slice(0, 5);
		const newPreviewImages = imagesToSubmit.map((file) =>
			URL.createObjectURL(file)
		);
		setPreviewImages((prevImages) => [...prevImages, ...newPreviewImages]);
	};

	// 사진 삭제
	const handleRemovePreview = (index: number) => {
		setPreviewImages((prevImages) => {
			const newImages = [...prevImages];
			newImages.splice(index, 1);
			return newImages;
		});
		setUploadFiles((prevFiles) => {
			const newFiles = [...prevFiles];
			newFiles.splice(index, 1);
			return newFiles;
		});
	};

	// 등록 버튼
	const onSubmit: SubmitHandler<formVillageCommu> = async (data) => {
		const formDataToSubmit = new FormData();
		Array.from(data.uploadFiles || []).forEach((image) => {
			formDataToSubmit.append('images', image);
		});

		// 카테고리(필수)
		if (!data.category) {
			// setCategoryError("! 카테고리 선택은 필수입니다.");
			// setFocus('category');
			const title = ''
			const message = '카테고리 선택은 필수입니다.'
			showDialog(OneButtonAlertDialog, {
				title,
				message,
			});
			return;
		} else {
			setCategoryError("");
		}
		// 내용(필수)
		if (!data.content) {
			// setContentError("! 내용 입력은 필수입니다.");
			// setFocus('content');
			const title = ''
			const message = '내용 입력은 필수입니다.'
			showDialog(OneButtonAlertDialog, {
				title,
				message,
			});
			return;
		} else {
			setContentError("");
		}

		// if (category === '' || content === '') {
		// 	return;
		// }

		VillageCommuAPI.writeVillageCommu(
			{
				...data,
				category: data.category,
				townId: Number(townId),
			},
			uploadFiles,
			pdfFiles
		)
			.then((res) => {
				// console.log('마을소통 글 등록', res);
				// navigate(`/commu_02/villageCm_03`)
				const tab = 'villageCommu';
				showDialog(SubmitSuccessDialog, { tab });
			})
			.catch((error) => {
				// console.log('마을소통 글 등록 실패', error);
				const message = error.response.data.message;
				showDialog(SubmitFailDialog, { message });
			});
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<div className="comm_write_wrap">
				<div className="cont-wrap">
					<h3 className="important">카테고리</h3>
					<div className="cont-box">
						<div className="select-box">
							{/* 마을뉴스 카테고리는 'AMN','MST', 'SMT' 한테만 보임 */}
							<select
								{...register('category')}
							// onChange={(e) => {
							// 	setCategory(e.target.value);
							// 	setCategoryError(false);
							// 	// updSubmitBtn();
							// }}
							>
								<option value="">선택</option>
								<option value='ASK'>마을질문</option>
								<option value='DAILY'>마을일상</option>
								{member &&
									(member.roles === 'AMN' ||
										member.roles === 'MST' ||
										member.roles === 'SMT') && (
										<option value='NEWS'>마을뉴스</option>
									)}
							</select>
							{categoryError && (
								<p className="error-message" style={{
									color: 'red',
									fontSize: '14px',
								}}>{categoryError}</p>
							)}
						</div>
					</div>
				</div>
				<div className="cont-wrap">
					<h3 className="important">내용</h3>
					<div className="cont-box">
						<textarea
							placeholder="내용을 입력하세요"
							defaultValue={''}
							{...register('content')}
						// value={cont}
						// onChange={(e) => {
						// 	setContent(e.target.value);
						// 	setContentError(false);
						// 	// updSubmitBtn();
						// }}
						/>
					</div>
					{contentError && (
						<p className="error-message"
							style={{
								color: 'red',
								fontSize: '14px',
							}}
						>{contentError}</p>
					)}
				</div>
				<div className="cont-wrap">
					<h3>첨부 이미지<br />(최대 15MB)</h3>
					<div className="cont-box">
						<div className="imgBox">
							<input className="upload-photo" />
							<label htmlFor="img">
								<img src={ic_cam} alt="사진 첨부" />
								사진 첨부
								<span>(최대 5장)</span>
							</label>
							<input
								type="file"
								id="img"
								{...register('uploadFiles')}
								accept="image/*"
								onChange={handleImageChange}
							/>
						</div>
						<div className="imglistBox">
							<ul>
								{previewImages.map((imageUrl, index) => (
									<li key={index}>
										<img src={imageUrl} key={imageUrl} />
										<button
											type="button"
											onClick={() => handleRemovePreview(index)}
											className="btn-file-delet"
										/>
									</li>
								))}
							</ul>
						</div>
					</div>
				</div>
				{/* 10.17 추가 - 파일첨부(pdf만 가능)	 */}
				<div className="cont-wrap">
					<h3>파일첨부(PDF)</h3>
					<div className="cont-box">
						<div className="fileBox">
							<input className="upload-name" defaultValue="선택한 파일 없음" placeholder="선택한 파일 없음" style={{ display: 'none' }} />
							<label htmlFor="file">파일 선택</label>
							<input
								type="file"
								id="file"
								{...register('pdfFiles')}
								accept="file/*"
								onChange={handleFileChange}
							/>
						</div>
						<div className="filelistBox mt10">
							<ul>
								{previewFiles.map((file, index) => (
									<li key={index}>
										<p key={`${index}_${file}`} className="txt_cut1">
											{typeof file === 'string' ? '' : file.name}
										</p>
										<p className='delet'>
											<button
												type="button"
												onClick={() => handleRemoveFilePreview(index)}
											>삭제</button>
										</p>
									</li>
								))}
							</ul>
						</div>
					</div>
				</div>
			</div>
			<div className="comm_btn_wrap">
				<button type="button" className="btn_cancel" onClick={handleCancle}>
					취소
				</button>
				<button type="submit"
					className={`btn_next ${allInput ? 'on' : ''}`}>
					등록
				</button>{' '}
				{/* 모두 체크 시 on클래스 붙음*/}
			</div>
		</form>
	);
};
export default Write_VillageCommuPage;
