import React, { useEffect, useState } from 'react';
import CommSelect from '../../components/common/CommSelect';
import SubLocation from '../../components/common/SubLocation';
import './Main_CommuPage.scss';
import SwiperCore, { Navigation, Pagination, Autoplay } from 'swiper';
import ic_rank01 from '../../assets/ic_rank01.png';
import ic_rank02 from '../../assets/ic_rank02.png';
import ic_rank03 from '../../assets/ic_rank03.png';
import img_gwangsan from '../../assets/img_gwangsan.png';
import img_vill01 from '../../assets/img_vill01.png';
import img_donggu from '../../assets/img_donggu.png';
import img_seogu from '../../assets/img_seogu.png';
import img_namgu from '../../assets/img_namgu.png';
import img_bukgu from '../../assets/img_bukgu.png';
import PaginationList from '../../components/common/Pagination';
import { useNavigate } from 'react-router-dom';
import TownAPI from '../../apis/TownAPI';
import TownList from '../../models/TownList';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useDialog } from '../../dialog/DialogProvider';
import RankDetailDialog from '../../dialog/CommuYard/RankDetailDialog';
import useMember from '../../hooks/useMember';
import { villageCommuContent } from '../../models/VillageCommuInfo';
import { CmMappingList, CmMasterVO } from '../../models/MappingList';
import ActivityLog, {
	ActivityLogList,
	Content,
} from '../../models/ActivityLog';
import { EduProgramList, ProgramContent } from '../../models/EduProgram';
import moment from 'moment';
import VillageCommuAPI from '../../apis/VillageCommuAPI';
import ActiveMapAPI from '../../apis/ActiveMapAPI';
import MapInfo, { AllcmMasterList } from '../../models/MapInfo';
import ActivityLogAPI from '../../apis/ActivityLogAPI';
import EduProgramAPI from '../../apis/EduProgramAPI';
import NavComponent from '../../components/common/NavComponent';
import noList from '../../assets/noList.png';
import { listenerCount } from 'process';
import { GetEnergy } from '../../models/MissionInfo';
import MissionAPI from '../../apis/MissionAPI';
import NoticeAPI from '../../apis/NoticeAPI';
import { NoticeList } from '../../models/Notice';
import PaginationComponent from '../../components/common/Pagination';

// 소통마당 메인
const SubLocationList = [
	{ href: '/platform_02/', text: '소개마당' },
	{ href: '/commu_02/', text: '소통마당' },
	{ href: '/reward_02/', text: '참여마당' },
	{ href: '/info_02/', text: '정보마당' },
];

const Main_CommuPage = () => {
	const { member } = useMember();
	const [townList, setTownList] = useState<TownList>();

	useEffect(() => {
		TownAPI.getTownList().then((res) => {
			// console.log('마을리스트', res);
			res &&
				res.content &&
				res.content.map((list, index) => {
					list.townTitle = list.districtDTOList
						.map((item) => item.districtName)
						.join(', ');
					if (list.townId == 1 || list.townId == 2 || list.townId == 11) {
						list.townImg = img_gwangsan;
					} else if (
						list.townId == 3 ||
						list.townId == 4 ||
						list.townId == 12
					) {
						list.townImg = img_donggu;
					} else if (list.townId == 5 || list.townId == 6) {
						list.townImg = img_seogu;
					} else if (
						list.townId == 7 ||
						list.townId == 8 ||
						list.townId == 13 ||
						list.townId == 15
					) {
						list.townImg = img_namgu;
					} else if (
						list.townId == 9 ||
						list.townId == 10 ||
						list.townId == 14
					) {
						list.townImg = img_bukgu;
					}
				});
			setTownList(res);
		});
	}, []);
	return (
		<main className="Main_CommuPage">
			<h1 className="notext">전체 영역</h1>
			<Nav />
			<article id="container">
				<h2 className="notext">컨텐츠 영역</h2>
				{/* 해당 페이지의 시작입니다 */}
				<div id="content">
					{/* 마을랭킹 */}
					{/* 2024.06.20 김덕현 수정 */}
					{/* <Village_Rank /> */}
					<NoticeContentList />
					{/* 마을입장 */}
					<Village_Select townList={townList ? townList : undefined} />
					{/* 모든 마을 최신글 */}
					<Village_Recent />
				</div>
				{/* 해당 페이지의 종료입니다 */}
			</article>
		</main>
	);
};

const Nav = () => {
	return (
		<nav id="lnb">
			<h2 className="notext">네비게이션 영역</h2>
			<SubLocation titleText1="소통마당" liList={SubLocationList} />
			<div className="lnb_txt">
				<h3>소통마당</h3>
				{/* <CommSelect/> */}
				<p>모든 마을의 최신 현황을 확인하고 내 마을에 들어가 소통해보세요.</p>
			</div>
		</nav>
	);
};
// 공지사항
const NoticeContentList = () => {
	const navigate = useNavigate();
	const [noticeContentList, setNoticeContentList] = useState([] as NoticeList[]);
	const [page, setPage] = useState(1);
	const [count, setCount] = useState(0);
	useEffect(() => {
		NoticeAPI.getNoticeList({
			page: page,
			pageSize: 5,
		}).then((res) => {
			if (res) {
				// console.log(res);
				setNoticeContentList(res);
				if (res[0]) {
					if (res[0].totalCnt) {
						setCount(res[0].totalCnt);
					}
				}
			}
		})
	}, [page])

	const handleDetail = (uid: number) => {
		sessionStorage.setItem('noticeN', String(uid));
		navigate('/notice_02/notice_03-detail')
	}

	const handlePageChange = (page: number) => {
		setPage(page);
	};
	return (
		<>
			<div className="village_cont mt40">
				<div className="cont_title">
					<h4>공지사항</h4>
				</div>
				<div className='cont_content mt25 village_table'>
					<ul className="thead noti pa20">
						<li>등록일</li>
						<li>제목</li>
					</ul>
					<ul className='tbody noti'>
						{noticeContentList &&
							noticeContentList.length !== 0 ?
							noticeContentList.map((list, index) => (
								<li className={`pa20 ${list.status === 'N' ? 'non ' : ''}`} key={index} onClick={() => handleDetail(list.nntUid)}>
									<a>
									{/* <p className='noticeTag'>{window.screen.width < 1070 ? '공지' : '공지사항'}</p> */}
										<p>{moment(list.crtDt).format('YYYY.MM.DD')}</p>
										<p className="txt_cut1 comm_title">{list.title}</p>
									{/* {window.screen.width < 800 ?
										<></>
										:
										<li className=''>{moment(list.crtDt).format('YYYY.MM.DD')}</li>
									} */}
									</a>
								</li>
								
							))
							:
							<p style={{ textAlign: 'center' }}>공지사항이 없습니다.</p>
						}
					</ul>
				</div>
			</div>
		</>
	);
}
// 마을 랭킹
SwiperCore.use([Navigation, Pagination, Autoplay]);
const Village_Rank = () => {
	const { showDialog } = useDialog();
	const [villRanking, setVillRanking] = useState<GetEnergy>();
	// 랭킹 자세히 보기 팝업
	const rankDetail = () => {
		// console.log('랭킹 자세히보기 클릭!!');

		showDialog(RankDetailDialog);
	};
	useEffect(() => {
		MissionAPI.getRanking({
			mssnDe: '마을미션',
		}).then((res) => {
			// console.log('마을미션 ::::::', res.data);
			setVillRanking(res.data);
		});
	}, []);
	return (
		<div className="village_cont">
			<div className="cont_title">
				<h4>마을 랭킹</h4>
				<button type="button" className="btn_mobile" onClick={rankDetail}>
					랭킹 자세히보기
				</button>
			</div>
			<div className="cont_content mt25 village_rank">
				<Swiper
					className="mySwiper"
					modules={[Navigation, Pagination, Autoplay]}
					loop={true}
					direction="vertical"
					autoplay={{
						delay: 2000,
						// pauseOnMouseEnter: true,
						// disableOnInteraction: false,
					}}
					navigation={{
						nextEl: '.swiper-button-next',
						prevEl: '.swiper-button-prev',
					}}
					pagination={{
						el: '.pagination',
						type: 'fraction',
					}}
				>
					<div className="swiper-wrapper">
						{villRanking &&
							villRanking?.rankings.map((list, idx) => (
								<SwiperSlide>
									<p
										className="swiper-slide swiper-slide-duplicate"
										data-swiper-slide-index={9}
										style={{ height: 90 }}
										key={idx}
									>
										{list?.rank < 4 ? (
											<span className="num">
												<img
													src={
														list?.rank === 1
															? ic_rank01
															: list?.rank === 2
																? ic_rank02
																: list?.rank === 3
																	? ic_rank03
																	: ''
													}
													alt="랭킹 아이콘"
												/>
											</span>
										) : (
											list?.rank >= 4 && (
												<>
													<span className="num">{list?.rank}</span>
												</>
											)
										)}
										<span className="villname">
											{list?.town?.townName}{' '}
											<strong>
												{' '}
												(
												{list?.town?.districts.map(
													(districtsList, districtsIndex) => (
														<> {districtsList?.districtName} </>
													)
												)}
												)
											</strong>
										</span>
										<span className="score">
											{' '}
											{list?.point.toLocaleString()} E
										</span>
									</p>
								</SwiperSlide>
							))}
						{/* <SwiperSlide>
							<p className="swiper-slide">
								<span className="num">
									<img src={ic_rank02} alt="랭킹 아이콘" />
								</span>
								<span className="villname">
									문산마을 <strong>(문흥1동, 문흥2동. 오치1동)</strong>
								</span>
								<span className="score">100점</span>
							</p>
						</SwiperSlide>
						<SwiperSlide>
							<p className="swiper-slide">
								<span className="num">
									<img src={ic_rank03} alt="랭킹 아이콘" />
								</span>
								<span className="villname">
									문산마을 <strong>(문흥1동, 문흥2동. 오치1동)</strong>
								</span>
								<span className="score">100점</span>
							</p>
						</SwiperSlide>
						<SwiperSlide>
							<p className="swiper-slide">
								<span className="num">4</span>
								<span className="villname">
									문산마을 <strong>(문흥1동, 문흥2동. 오치1동)</strong>
								</span>
								<span className="score">100점</span>
							</p>
						</SwiperSlide>
						<SwiperSlide>
							<p className="swiper-slide">
								<span className="num">5</span>
								<span className="villname">
									문산마을 <strong>(문흥1동, 문흥2동. 오치1동)</strong>
								</span>
								<span className="score">100점</span>
							</p>
						</SwiperSlide>
						<SwiperSlide>
							<p className="swiper-slide">
								<span className="num">6</span>
								<span className="villname">
									문산마을 <strong>(문흥1동, 문흥2동. 오치1동)</strong>
								</span>
								<span className="score">100점</span>
							</p>
						</SwiperSlide>
						<SwiperSlide>
							<p className="swiper-slide">
								<span className="num">7</span>
								<span className="villname">
									문산마을 <strong>(문흥1동, 문흥2동. 오치1동)</strong>
								</span>
								<span className="score">100점</span>
							</p>
						</SwiperSlide>
						<SwiperSlide>
							<p className="swiper-slide">
								<span className="num">8</span>
								<span className="villname">
									문산마을 <strong>(문흥1동, 문흥2동. 오치1동)</strong>
								</span>
								<span className="score">100점</span>
							</p>
						</SwiperSlide>
						<SwiperSlide>
							<p className="swiper-slide">
								<span className="num">9</span>
								<span className="villname">
									문산마을 <strong>(문흥1동, 문흥2동. 오치1동)</strong>
								</span>
								<span className="score">100점</span>
							</p>
						</SwiperSlide>
						<SwiperSlide>
							<p className="swiper-slide">
								<span className="num">10</span>
								<span className="villname">
									문산마을 <strong>(문흥1동, 문흥2동. 오치1동)</strong>
								</span>
								<span className="score">100점</span>
							</p>
						</SwiperSlide> */}
					</div>
					<div className="swiper-button-wrap">
						<div className="swiper-button-prev"></div>
						<div className="swiper-button-next"></div>
					</div>
				</Swiper>
			</div>
		</div>
	);
};

// 마을 입장
const Village_Select = ({ townList }: { townList?: TownList }) => {
	const navigate = useNavigate();
	const { member } = useMember();
	const [villageIn, setVillageIn] = useState(false);
	// const [townList, setTownList] = useState<TownList>();

	// 서브 네비게이션 열고닫기
	// useEffect(() => {
	// 	$('body').on('click', '.select_custom_dep1_li > a ', function () {
	// 		const idx1 = $('.select_custom_dep1_li').index($(this).parent());
	// 		$('.select_custom_dep1_li').each(function (index) {
	// 			console.log("1 > ", idx1, "2 > ", index)
	// 			if (idx1 == index) {
	// 				if ($(this).is('.on') == false) {
	// 					$(this).addClass('on');
	// 					$(this).children('ul').stop().slideDown(150);
	// 				} else {
	// 					$(this).removeClass('on');
	// 					$(this).children('ul').stop().slideUp(75);
	// 				}
	// 			} else {
	// 				$(this).removeClass('on');
	// 				$(this).children('ul').stop().slideUp(75);
	// 			}
	// 		});
	// 	});
	// });
	const handleVillageIn = () => {
		setVillageIn(!villageIn)
	}
	// 마을 상세로 이동(임시)
	const handleVillage = (townId: number) => {
		// console.log('클릭한 마을 메인으로 이동', townId);
		navigate(`/commu_02/mainVillage_03`);
		// sessionStorage.getItem("town");
		sessionStorage.setItem('town', String(townId));
	};

	return (
		<div className="village_cont mt40">
			<div className="cont_title">
				<h4>마을 입장</h4>
				{/* <button type="button" className="only_mobile">선택한 마을입장</button> */}
			</div>

			{/* 웹 */}
			<div className="cont_content mt25 village_list">
				<ul>
					{/* 내마을일때 myvill 클래스 붙음 */}
					{townList &&
						townList?.content &&
						townList?.content.map((list, index) => (
							<li
								key={index}
								className={
									member &&
										member.townDTO &&
										list &&
										list.townId === member?.townDTO.townId
										? 'myvill'
										: ''
								}
								onClick={() => handleVillage(list.townId)}
							>
								<a>
									<div className="txt">
										{/* <p className="villname1">{list.townTitle}</p> */}
										<p><span style={{ fontSize: '13px', color: '#666' }}>({list.townTitle})</span></p>
										<p className="villname2">{list.townName}</p>
									</div>
									<div className="img">
										<img src={list.townImg} alt="마을 이미지" />
									</div>
								</a>
							</li>
						))} 
				</ul>

				{/* 모바일 */}
				<div className="select_custom only_mobile">
					<ul className="select_custom_dep1">
						<li className={`select_custom_dep1_li end ${villageIn ? 'on' : ''}`}>
							<a onClick={() => handleVillageIn()}>
								<div className="txt">
									<p className="villname1">마을을 선택해주세요.</p>
									{/* <p className="villname2 myvill">{townList?.content[0].townName}</p> */}
								</div>
							</a>
							<ul className={`select_custom_dep2 ${villageIn ? 'slide-down' : 'slide-up'}`}>
								{townList &&
									townList?.content &&
									townList?.content.map((list, index) => (
										<li
											className="active"
											key={index}
											onClick={() => handleVillage(list.townId)}
										>
											<a>
												<div className="txt">
													<p className="villname1">{list.townTitle}</p>
													<p
														className={`villname2 ${member &&
															member.townDTO &&
															list.townId === member?.townDTO.townId
															? 'myvill'
															: ''
															}`}
													>
														{list.townName}
													</p>
												</div>
											</a>
										</li>
									))}
							</ul>
						</li>
					</ul>
				</div>
			</div>
		</div>
	);
};

// 모든 마을 최신글
const Village_Recent = () => {
	const navigate = useNavigate();
	const { member } = useMember();
	const [page, setPage] = useState<number>(1);

	const handlePageChange = (page: number) => {
		setPage(page);
	};
	const [tab, setTab] = useState<string>('0');
	// 모든마들 마을소통 목록 조회
	const [commuList, setCommuList] = useState([] as villageCommuContent[]);

	// 모든 마을 활동지도 목록조회
	const [mapList, setMapList] = useState([] as AllcmMasterList[]);

	// 모든 마을 활동일지 목록조회
	const [logList, setLogList] = useState<Content[] | undefined>();

	// 모든 마을 교육프로그램 목록조회
	const [programList, setProgramList] = useState<EduProgramList>();

	const [length, setLength] = useState(false);

	useEffect(() => {
		// 마을소통
		const listParams: {
			// townId: number;
			page: number;
			size: number;
			// category?: string;
			keyword?: string;
			sort?: string;
		} = {
			// townId: Number(townId),
			page: Number(page) - 1,
			size: 6,
			sort: 'createdAt,DESC',
		};

		VillageCommuAPI.getVillageCommuList(listParams).then((res) => {
			// console.log('getVillageCommuList', res.content);

			setCommuList(res.content);
			if (res.content && res.content.length == 0) {
				setLength(true);
			} else {
				setLength(false);
			}
		});
		// 활동 지도
		ActiveMapAPI.getAllMapList({
			// tcUid: Number(townId),
			crtUid: Number(member?.id) || undefined,
			pageSize: 6,
			prgrsSt: '진행',
			// page: page,
			// search: search,
		})
			.then((res) => {
				// console.log('getAllMapList', res);
				setMapList(res.allcmMasterList);
				// setCount(Number(res.allcmMasterList[0].totalCount));
			})
			.catch((e) => { });
		// 활동 일지
		ActivityLogAPI.getSubjectList({
			page: page - 1,
			// townId: Number(townId),
			// keyword: searchKeyword ? searchKeyword : undefined,
			sort: 'createdAt,DESC',
			size: 6,
		})
			.then((res) => {
				// console.log('getSubjectList ', res);
				setLogList(res.content);
				const nowDate = new Date();
				// if (res?.content) {
				// 	res.content.map((list) => {
				// 		const formattedStartDate = `${list.startedAt[0]}.${String(
				// 			list.startedAt[1]
				// 		).padStart(2, '0')}.${String(list.startedAt[2]).padStart(2, '0')}`;
				// 		const formattedEndDate = `${list.endedAt[0]}.${String(
				// 			list.endedAt[1]
				// 		).padStart(2, '0')}.${String(list.endedAt[2]).padStart(2, '0')}`;
				// 		const startDate = new Date(formattedStartDate);
				// 		const endDate = new Date(formattedEndDate);
				// 		if (nowDate >= startDate && nowDate <= endDate) {
				// 			list.status = 'ing';
				// 		}
				// 		// console.log('aaaa', list.status);
				// 		// console.log(
				// 			'목록',
				// 			res.content
				// 				.filter((filter) => filter.status === 'ing')
				// 				.slice(0, 4)
				// 		);
				// 		setLogList(
				// 			res.content
				// 				.filter((filter) => filter.status === 'ing')
				// 				.slice(0, 4)
				// 		);
				// 	});
				// }
			})
			.catch((error) => {
				// console.log(error);
			});
		// 교육프로그램
		EduProgramAPI.getEduProgramList({
			page: page - 1,
			size: 6,
			sort: 'createdAt,DESC',
			status: 'b',
			// status: selectedStatus == "" ? undefined : selectedStatus,
		}).then((res) => {
			// console.log('getEduProgramList', res);
			setProgramList(res);
		});
	}, [length]);

	const handleTab = (e: any) => {
		setTab(e.target.value);
	};
	// 마을 소통 디테일 페이지
	const handleDetailCommu = (
		boardId: string,
		createdId: string,
		townId: string
	) => {
		// console.log('마을소통 상세보기 이동');
		sessionStorage.setItem('createdId', String(createdId));
		sessionStorage.setItem('town', String(townId));
		// sessionStorage.setItem('visible', String(visible));

		navigate(`/commu_02/villageCm_03-detail?boardId=${boardId}`);
		sessionStorage.setItem('boardId', String(boardId));
	};
	// 활동 지도 디테일 페이지
	const handleDetailMap = (cmmUid: string, crtUid: number, tcUid: string) => {
		navigate('/commu_02/actMap_03-detail');
		sessionStorage.setItem('cmmUid', String(cmmUid));
		sessionStorage.setItem('crtUid', String(crtUid));
		sessionStorage.setItem('town', String(tcUid));
	};
	// 주제내역 이동
	const handleLog = (subjectId: number, townId: string) => {
		sessionStorage.setItem('subject', String(subjectId));
		sessionStorage.setItem('town', String(townId));
		navigate('/commu_02/active_03-history');
	};
	// 상세화면 이동
	const handleDetailPro = (sfUid: number, tcUid: string) => {
		sessionStorage.setItem('space', String(sfUid));
		sessionStorage.setItem('town', String(tcUid));
		navigate('/commu_02/eduProgram_03-detail');
	};

	// 월과 일이 한 자릿수일 때, 앞에 0을 붙이기
	const formattedMonth =
		logList?.map((list, index) => {
			const month = parseInt(list.createdAt[1]);
			return month < 10 ? `0${month}` : month;
		}) || [];
	const formattedDay =
		logList?.map((list, index) => {
			const day = parseInt(list.createdAt[2]);
			return day < 10 ? `0${day}` : day;
		}) || [];

	return (
		<div className="village_cont mt40">
			{/* <div className="cont_title">
				<h4>모든 마을 최신글</h4>
				<select onChange={(e) => setTab(e.target.value)}>
					<option value={0}>마을소통</option>
					<option value={1}>활동지도</option>
					<option value={2}>활동일지</option>
					<option value={3}>교육프로그램</option>
				</select>
			</div> */}
			<div className="cont_tab">
				<button
					type="button"
					// className="tab tab_active"
					className={tab === '0' ? 'tab tab_active' : 'tab non_active'}
					// value={0}
					onClick={() => setTab('0')}
				>
					마을소통
				</button>
				<button
					type="button"
					// className="tab non_active"
					className={tab === '1' ? 'tab tab_active' : 'tab non_active'}
					onClick={() => setTab('1')}
				>
					활동지도
				</button>
				<button
					type="button"
					// className="tab non_active"
					className={tab === '2' ? 'tab tab_active' : 'tab non_active'}
					onClick={() => setTab('2')}
				>
					활동일지
				</button>
				<button
					type="button"
					// className="tab non_active"
					className={tab === '3' ? 'tab tab_active' : 'tab non_active'}
					onClick={() => setTab('3')}
				>
					교육프로그램
				</button>
			</div>
			{/* 마을소통 */}
			{tab === '0' && !length ? (
				<div className="cont_content mt25 village_table">
					<ul className="thead pa20">
						{/* <li>메뉴명</li> */}
						<li>마을명</li>
						<li>내용</li>
						<li>등록일</li>
					</ul>
					<ul className="tbody">
						{commuList &&
							commuList?.map((commuList, commuIndex) => (
								<li
									className={`${commuList.visible === 'N' ? 'non ' : ''}pa20`}
									key={commuIndex}
									onClick={() =>
										handleDetailCommu(
											String(commuList.boardId),
											String(commuList.createdId),
											String(commuList?.townId)
										)
									}
								>
									{/* <p className="menu">
										{commuList?.category === 'ASK'
											? '마을질문'
											: commuList?.category === 'NEWS'
											? '마을뉴스'
											: commuList?.category === 'DAILY'
											? '마을일상'
											: ''}
									</p> */}
									<a>
										<p className="villname">{commuList?.townName}</p>
										<p className="txt_cut1 comm_title">{commuList?.content}</p>
										<p>{`${moment(commuList.createdAt).format(
											'yyyy.MM.DD'
										)}`}</p>
									</a>
								</li>
							))}
					</ul>
				</div>
			) : tab === '0' && length ? (
				<div className="comm_noList">
					<img src={noList} alt="내용없음 이미지" />
					<p className="mt25">작성된 게시글이 없습니다.</p>
				</div>
			) : (
				''
			)}

			{/* 활동지도 */}
			{tab === '1' && mapList && mapList.length !== 0 ? (
				<div className="cont_content mt25 village_table">
					<ul className="thead pa20">
						<li>마을명</li>
						<li>내용</li>
						<li>등록일</li>
					</ul>
					<ul className="tbody">
						{mapList &&
							mapList.map((mapList, mapIndex) => (
								<li
									className={`${mapList.openTp === 'N' ? 'non ' : ''}pa20`}
									key={mapIndex}
									onClick={() =>
										handleDetailMap(
											String(mapList?.cmmUid),
											Number(mapList?.crtUid),
											String(mapList?.cmtcUid)
										)
									}
								>
									<a>
										<p className="villname">{mapList?.cmtitle}</p>
										<p className="txt_cut1 comm_title">{mapList?.title}</p>
										<p>{`${moment(mapList.crtDt).format('yyyy.MM.DD')}`}</p>
									</a>
								</li>
							))}
					</ul>
				</div>
			) : tab === '1' && mapList && mapList.length === 0 ? (
				<div className="comm_noList">
					<img src={noList} alt="내용없음 이미지" />
					<p className="mt25">진행 중인 활동지도가 없습니다.</p>
				</div>
			) : (
				''
			)}

			{/* 활동일지 */}
			{tab === '2' && logList && logList.length !== 0 ? (
				<div className="cont_content mt25 village_table">
					<ul className="thead pa20">
						{/* <li>메뉴명</li> */}
						<li>마을명</li>
						<li>제목</li>
						<li>등록일</li>
					</ul>
					<ul className="tbody">
						{logList &&
							logList?.map((logList, logIndex) => (
								<li
									className={`${logList.status === 'N' ? 'non ' : ''}pa20`}
									key={logIndex}
									onClick={() =>
										handleLog(
											Number(logList?.subjectId),
											String(logList?.town.townId)
										)
									}
								>
									<a>
										<p className="villname">{logList?.town?.townName}</p>
										<p className="txt_cut1 comm_title">{logList?.title}</p>
										<p>
											{logList.createdAt[0]}.{formattedMonth[logIndex]}.
											{formattedDay[logIndex]}
										</p>
									</a>
								</li>
							))}
					</ul>
				</div>
			) : tab === '2' && logList && logList.length == 0 ? (
				<div className="comm_noList">
					<img src={noList} alt="내용없음 이미지" />
					<p className="mt25">작성된 게시글이 없습니다.</p>
				</div>
			) : (
				''
			)}

			{/* 교육프로그램 */}
			{tab === '3' && programList && programList.content.length !== 0 ? (
				<div className="cont_content mt25 village_table">
					<ul className="thead pa20">
						<li>마을명</li>
						<li>내용</li>
						<li>등록일</li>
					</ul>

					<ul className="tbody">
						{programList &&
							programList?.content.map((proList, proIndex) => (
								<li
									className={`${proList.visible === 'N' ? 'non ' : ''}pa20`}
									key={proIndex}
									onClick={() =>
										handleDetailPro(
											Number(proList?.sfUid),
											String(proList?.tcUid)
										)
									}
								>
									<a>
										<p className="villname">{proList?.townName}</p>
										<p className="txt_cut1 comm_title">
											{proList?.facilityName}
										</p>
										<p>{`${moment(proList.createdAt).format('yyyy.MM.DD')}`}</p>
									</a>
								</li>
							))}
					</ul>
				</div>
			) : tab === '3' && programList && programList.content.length == 0 ? (
				<div className="comm_noList">
					<img src={noList} alt="내용없음 이미지" />
					<p className="mt25">진행 중인 프로그램이 없습니다.</p>
				</div>
			) : (
				''
			)}
		</div>
	);
};

export default Main_CommuPage;
