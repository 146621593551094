import React, { useEffect, useState } from 'react';
import './Write02_CenterInroPage.scss';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useDialog } from '../../../dialog/DialogProvider';
import { useNavigate } from 'react-router-dom';
import PostCodeDialog from '../../../dialog/activeMap/PostCodeDialog';
import IntroAPI, { CenterParams } from '../../../apis/IntroAPI';
import IntroInfo from '../../../models/IntroInfo';
import SubmitSuccessDialog from '../../../dialog/common/SubmitSuccessDialog';
import SubmitFailDialog from '../../../dialog/common/SubmitFailDialog';
import ic_cam from '../../../assets/ic_cam.png';
import OneButtonAlertDialog from '../../../dialog/OneButtonAlertDialog';
const Write02_CenterInroPage = () => {
	const townId = sessionStorage.getItem('town');
	const { showDialog } = useDialog();
	const navigate = useNavigate();
	const { register, handleSubmit, getValues, setValue, watch } =
		useForm<IntroInfo>();
	// 더블클릭 등록 방지
	const [noDoubleClick, setNoDoubleClick] = useState(false);
	// 동록 버튼 활성화
	const [buttonOn, setButtonOn] = useState(false);
	const centerName = watch('centerName');
	const location = watch('location');
	const subLocation = watch('subLocation');
	const openDt = watch('openDt');
	const openingCeremony = watch('openingCeremony');
	const [errorName, setErrorName] = useState('');
	const [errorLocaInfo, setErrorLocaInfo] = useState('');
	const [errorOpenDt, setErrorOpenDt] = useState('');
	const [errorCeremony, setErrorCeremony] = useState('');
	const geocoder = new window.kakao.maps.services.Geocoder();
	// 주소검색
	const [address, setAddress] = useState('');
	// 경도 x
	const [lat, setLat] = useState();
	// 위도 y
	const [lng, setLng] = useState();
	const handleSearch = () => {
		showDialog(PostCodeDialog, {
			onComplete(address) {
				// // console.log("address >>>> ", address);
				setAddress(address.roadAddress);
				geocoder.addressSearch(address.address, (result: any, status: any) => {
					// console.log('result x>>>', result[0].x);
					// console.log('result y >>>', result[0].y);
					setLat(result[0].x);
					setLng(result[0].y);
				});
			},
		});
	};
	// 등록 버튼 on
	useEffect(() => {
		if (centerName && openDt && openingCeremony) {
			setButtonOn(true);
		} else {
			setButtonOn(false);
		}
	}, [centerName, openDt, openingCeremony]);
	// 첨부파일
	const [previewImages, setPreviewImages] = useState<string[]>([]);
	const [centerFile, setCenterFile] = useState([] as File[]);
	// 이미지 파일 첨부
	const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		// // console.log('files ::::::: ', event.target.files);
		const files = event.target.files;
		if (!files) return;
		const selectedImages = Array.from(files);
		// 이미지를 추가할 때 previewImages 배열의 길이가 1를 넘어가지 않도록 처리
		if (previewImages.length + selectedImages.length > 1) {
			// alert('사진은 최대 5개 까지 업로드할수있습니다.');
			const title = '';
			const message = '사진은 1장만 업로드할 수 있습니다.';
			showDialog(OneButtonAlertDialog, {
				title,
				message,
			});
			return;
		}
		if (selectedImages && selectedImages[0].size > 15000000) {
			const title = '';
			const message = '파일 크기는 최대 15MB까지만 가능합니다.';
			showDialog(OneButtonAlertDialog, {
				title,
				message,
			});
			return;
		}
		if (!(selectedImages[0].type === 'image/png' || selectedImages[0].type === 'image/jpeg')) {
			const title = '';
			const message = '파일 형식은 png, jpg 만 등록 가능합니다.';
			showDialog(OneButtonAlertDialog, {
				title,
				message,
			});
			return;
		}
		setCenterFile((prevImages: any) => {
			const newImages = [...prevImages];
			selectedImages.slice(0, 5).forEach((image) => newImages.push(image));
			// // console.log('imagesToSubmit ::::::::::::::::: ', uploadFiles);
			return newImages;
		});
		// 미리보기 이미지 추가
		const imagesToSubmit = selectedImages.slice(0, 5);
		const newPreviewImages = imagesToSubmit.map((file) =>
			URL.createObjectURL(file)
		);
		setPreviewImages((prevImages) => [...prevImages, ...newPreviewImages]);
	};

	// 첨부한 이미지 파일 x 버튼 누를시 삭제
	const handleRemovePreview = (index: number) => {
		setPreviewImages((prevImages) => {
			const newImages = [...prevImages];
			newImages.splice(index, 1);
			return newImages;
		});
		setCenterFile((prevFiles) => {
			const newFiles = [...prevFiles];
			newFiles.splice(index, 1);
			return newFiles;
		});
	};
	const onSubmit: SubmitHandler<IntroInfo> = async (data) => {
		// console.log('address', address);

		// console.log('data:::::', data);

		if (!data.centerName) {
			setErrorName('명칭을 입력해주세요.');
			return;
		}
		// if (!data.location) {
		// 	setErrorLocaInfo('주소를 입력해주세요.');
		// 	return;
		// }
		if (!data.openDt) {
			setErrorOpenDt('개소식 일자를 입력해주세요.');
		}
		if (!data.openingCeremony) {
			setErrorCeremony('내용을 입력해주세요.');
		}

		try {
			// console.log('address 2222222', address);
			await IntroAPI.insertCenter(
				{
					...data,
					townId: Number(townId),
					location: address,
					subLocation: data.subLocation,
					openDt: data.openDt,
					openingCeremony: data.openingCeremony,
				},
				centerFile
			)
				.then((res) => {
					// console.log('insertCenter res :::', res);
					if (res.status === 200) {
						const tab = 'center';
						showDialog(SubmitSuccessDialog, { tab });
						setNoDoubleClick(false);
					} else {
						setNoDoubleClick(false);
						const message = res.data.message;
            			showDialog(SubmitFailDialog, { message });
					}
				})
				.catch((e) => {
					const title = '사진 첨부';
					const message = '사진은 한 장에 최대 10MB 등록이 가능합니다.';
					showDialog(OneButtonAlertDialog, { title, message });
				});
		} catch { }
	};

	const handleCancle = () => {
		navigate(-1);
	};
	return (
		<main className="Write02_CenterInroPage">
			<h1 className="notext">전체 영역</h1>
			<nav id="lnb">
				<h2 className="notext">네비게이션 영역</h2>

				<div className="lnb_txt">
					<h3>거점센터 등록하기</h3>
				</div>
			</nav>
			<article id="container">
				<h2 className="notext">컨텐츠 영역</h2>
				{/* <!-- 해당 페이지의 시작입니다 --> */}
				<div id="content">
					<form onSubmit={handleSubmit(onSubmit)}>
						<div className="comm_write_wrap">
							<div className="cont-wrap">
								<h3 className="important">명칭</h3>
								<div className="cont-box">
									<input
										type="text"
										className="long"
										placeholder="사업명을 입력하세요"
										{...register('centerName')}
									/>
								</div>
								{errorName && (
									<p className="error" style={{ color: 'red' }}>
										{errorName}
									</p>
								)}
							</div>
							<div className="cont-wrap">
								<h3 className="important">위치</h3>
								<div className="cont-box">
									<input
										type="text"
										className="address"
										placeholder="주소를 입력하세요"
										value={address}
										{...register('location')}
										disabled
									/>
									<button
										type="button"
										className="btn_address"
										onClick={handleSearch}
									>
										주소검색
									</button>
									<input
										type="text"
										className="detail_address"
										placeholder="상세주소를 입력하세요"
										{...register('subLocation')}
									/>
								</div>
								{/* {errorLocaInfo && (
									<p className="error" style={{ color: 'red' }}>
										{errorLocaInfo}
									</p>
								)} */}
							</div>
							<div className="cont-wrap">
								<h3 className="important">개소식</h3>
								<div className="cont-box">
									<div className="date_box">
										<input
											type="date"
											data-placeholder="개소식 날짜"
											{...register('openDt')}
										/>
									</div>
									{errorOpenDt && (
										<p className="error" style={{ color: 'red' }}>
											{errorOpenDt}
										</p>
									)}
									<textarea
										placeholder="내용을 입력하세요"
										className="mt10"
										{...register('openingCeremony')}
									></textarea>
								</div>
								{errorCeremony && (
									<p className="error" style={{ color: 'red' }}>
										{errorCeremony}
									</p>
								)}
							</div>
							<div className="cont-wrap">
								<h3>대표 이미지</h3>
								<div className="cont-box">
									<div className="imgBox">
										<input className="upload-photo" />
										<label htmlFor="img">
											<img src={ic_cam} alt="사진 첨부" />
											사진 첨부
											<span>(1장)</span>
										</label>
										<input
											type="file"
											id="img"
											accept="image/*"
											onChange={handleImageChange}
										/>
									</div>
									<div className="imglistBox">
										<ul>
											{previewImages.map((imageUrl, index) => (
												<li key={index}>
													<img src={imageUrl} key={imageUrl} />
													<button
														type="button"
														onClick={() => handleRemovePreview(index)}
														className="btn-file-delet"
													/>
												</li>
											))}
										</ul>
									</div>
								</div>
							</div>
						</div>
						<div className="comm_btn_wrap">
							<button
								type="button"
								className="btn_cancel"
								onClick={handleCancle}
							>
								취소
							</button>
							<button
								type="submit"
								// className="btn_next on"
								className={`btn_next ${buttonOn ? 'on' : ''}`}
							>
								등록
							</button>
							{/* <!-- 모두 체크 시 on클래스 붙음--> */}
						</div>
					</form>
				</div>
				{/* <!-- 해당 페이지의 종료입니다 --> */}
			</article>
		</main>
	);
};

export default Write02_CenterInroPage;
